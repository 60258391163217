

@use "sass:math";

// also for blog series navigation on single post 
.encyclopedia-prefix-filters, 
.pagination-nav {
	text-align: center;
	width: 100%;
	padding-left: math.div($grid-gutter-width, 2);
	margin-top: 30px;
	margin-bottom: 90px;
	.filter-level,
	.nav-wrap {
		//display: flex;
		//justify-content: space-evenly;
		&.level-2 {
			display: none;
		}
		.filter,
		.nav {
			display: inline-block;
			font-size: 15px;
			font-weight: $medium;
			background: $card-shadow-white;
			box-shadow: $card-shadow;
			border-radius: 4px;
			text-align: center;
			margin-right: 20px;
			&:last-of-type {
				margin-right: 0;
			}
			a {
				display: inline-block;
				line-height: 1;
				width: calc(1em + 22px);
				padding: 11px;
				color: black;
			}
			&.current-filter, &:hover {
				background: $lightorange;
				a {
					color: white;
				}
			}
		}
	}
}

.glossary-prefix {
	margin-bottom: 90px;
	.glossary-title {
		color: #401D7B;
		font-size: 24px;
	}
	.glossary-excerpt {
		color: $text-grey-palatino;
		font-family: $palatino;
	}
}

// archive / tax
.post-type-archive-encyclopedia, .tax-encyclopedia-category  {
	.archive-meta {
		font-size: 26px;
		color: lighten( $purple, 60% );
		letter-spacing: -0.34px;
		line-height: 34px;
		margin-top: 22px;
		margin-bottom: 40px;
	}
	hr.orange {
		margin-bottom: 30px;
	}
	.glossary-item.col-lg-5 {
		margin-bottom: 75px;
	}
}

.tax-encyclopedia-category {
	.header {
		padding-top: 25px; //88px;
		padding-bottom: 0;
		.entry-title {
			line-height: .7;
			text-align: right;
			font-size: 41px;
			text-transform: uppercase;
			letter-spacing: -.53px;
			color: rgba(255,255,255,.6);
			margin: 0;
			span {
				color: white;
			}
		}
	}
}

.glossary-main {
	.encyclopedia-prefix-filters {
		//display: none;
	}
	.header {
		text-align: center;
	}
	.glossary-feed-wrap {
		padding-top: 80px;
		padding-bottom: 80px;
	}
	.glossary-feed {
		column-count: 3;
		column-gap: $grid-gutter-width+40px;
		padding-bottom: 150px;
		.entry-encyclopedia {
			color: black;
			font-weight: $light;
			margin-bottom: 10px;
			display: grid;
			grid-template-rows: 1fr auto;
			break-inside: avoid;
			padding-bottom: 2em;
			a {
				color: $text-darkgrey;
				line-height: 2.2;
				&:hover {
					color: $purple;
				}
			}
		}
		.letter {
			display: block;
			text-transform: capitalize;
			font-size: 24px;
			font-weight: $medium;
			line-height:0;
			padding-bottom: 0;
			border-bottom: 1px dotted $hr-grey;
			margin: 0 0 8px 0;
		}
	}
}

.dolbert-wrap {
	position: relative;
	text-align: right;
	.dolbert {
		position: absolute;
		right: -15px;
		bottom: 50px;
		max-width: 100%;
		max-width: 228px;
	}
	.btn {
		font-size: 28px;
		text-transform: none;
		padding: 13px 50px;
		line-height: 1;
		position: relative;
	}
}

.glossary-info {
	font-weight: $light;
	.topics {
		a {
			display: inline-block;
			color: $text-darkgrey;
			font-weight: $regular;
			margin-right: 5px;
			&:first-of-type {
				margin-left: 5px;
			}
		}
	}
	.last-updated {
		color: $text-lightgrey-2;
	}
}

// single encyclopedia

.single.single-encyclopedia {
	.header {
		text-align: center;
		padding-top: 60px;
		margin-bottom: 0;
		.entry-title {
			font-size: 38px;
			letter-spacing: -.45px;
			line-height: 44px;
			color: black;
			margin-bottom: 20px;
		}
		.entry-excerpt {
			color: $text-grey-palatino;
			margin-top: 30px;
		}
		.glossary-featured {
			max-width: 100%;
			height: auto;
		}
	}
	.entry-content {
		margin-bottom: 0;
	}
	.related-divs {
		margin-top: 60px;
		margin-bottom: 60px;
	}
}

.single-encyclopedia {
	.encyclopedia-prefix-filters {
		margin-bottom: 0;
	}
	.spoken {
		font-family: Arial, Helvetica, sans-serif;
		// font-weight: $medium;
		margin-bottom: 30px;
		&:after {
			content: "";
			border-bottom: 4px solid $lightorange;
			width: 94px;
			display: block;
			margin: 10px auto;
		}
	}
	.entry-content {
		/*> p:first-of-type {
			&:first-letter {
				float: left;
				font-size: 52px;
				line-height: 40px;
				padding-top: 4px;
				padding-right: 8px;
				padding-left: 3px;
			}
		}*/
		img {
			margin-left: -20px;
			margin-right: -20px;
			max-width: calc(100% + 40px);
			height: auto;
		}
	}
	.related-div {
		h3 {
			@extend .h6;
		}
		ul {
			width: 100%;
		    list-style-type: none;
		    padding: 0;
		    margin-bottom: 20px;
		    margin-top: 0;
			li { 
				display: inline-block;
				margin-top: 0.5em;
				margin-right: 0.75em;
				margin-bottom: 0.25em;
				a { 
					display: block; //
				    background-color: $lightpurple;
					color: #fff;
					font-size: 16px;
					line-height: 1;
	        		font-weight: $medium;
	        		text-decoration: none;
					padding: 8px 11px;
					border-radius: 4px;
			        &:hover {
			        	background-color: darken($lightpurple, 10%);
			        }
				}
			}
		}
		&.related-topics ul li a {
			background-color: $orange;
	    &:hover {
	      background-color: darken($orange, 10%);
	    }
		}
	  &.related-terms ul li a:before {
	    content: "#";
	    color: hsla(0, 0%, 100%, 0.8);
	    margin-right: .15em;
	  }
	}
}


// zotPress


#ZOTPRESS-references.h6 {
	margin-bottom: 16px;
}

.zp-Zotpress H3, .zp-EntryYear H3 {
	display: none; // removes the weird headlines in the references list
}

.zp-List {
	.csl-bib-body {
	    text-indent: 0 !important;
		padding-left: 0 !important;
	}
}

.zp-Zotpress {
	.csl-entry {
		font-family: $palatino;
		font-size: 16px;
		line-height: 22px;
		margin-bottom: 20px;
		color: $text-grey;
		a { 
			color: inherit;
		}
		i {
			font-style: italic;
		}
	}
}

@include media-breakpoint-up(lg) {
	.post-type-archive-encyclopedia, .tax-encyclopedia-category {
		.glossary-item.col-lg-5 {
			&:nth-child(2n+1) {
				margin-left: 8.33333%;
			}
		}
	}
}
 
@include media-breakpoint-up(xl) {
	.post-type-archive-encyclopedia, .tax-encyclopedia-category {
		.header {
			padding-top: 84px;
			padding-bottom: 55px;
		}
	}
}

@include media-breakpoint-down(md) {
	.dolbert-wrap .btn {
	    font-size: 18px;
	    padding: 10px;
	}
	.single.single-encyclopedia .header {
		padding-top: 40px;
	}
}

@include media-breakpoint-down(md) {
	.encyclopedia-prefix-filters {
		.filter-level {
		    display: inline-block;
			display: -webkit-inline-box;
			text-align: center;
			.filter {
				display: inline-block;
				margin: 4px;
			}
		}
	}
	.glossary-main .glossary-feed {
		column-count: 1;
	}
	.dolbert-wrap {
		.dolbert {
			bottom: 120px;
			right: 0;
		}
		.btn {
			font-size: 16px;
			min-width: unset;
			//right: 20px;
		}
	}
	footer {
		padding-bottom: 0;
	}
}