f

html body {
	font-family: $roboto;
	.entry-title {
		font-family: $roboto;
	}
}

.page-template-default .has-post-thumbnail, 
.page-template-page-navbar-left .has-post-thumbnail, 
.single-post .display-banner { //.has-post-thumbnail:not(.has-post-video):not(.no-banner) {
	.header {
		background-image: $linear;
		background-repeat: no-repeat;
		//background-attachment: fixed;
		background-position: center;
		background-size: cover;
		background-blend-mode: saturation;
		min-height: 40vh;
	}
	.header {
		position: relative;
		padding-top: 144px;
		padding-bottom: 86px;
		margin-bottom: 90px;
		.entry-title, .entry-excerpt {
			color: white;
		}
		.entry-excerpt {
			margin-top: 12px;
			margin-left: -.04em;
			p {
				font-size: 64px;
				line-height: 100%;
				font-weight: $medium;
			}
		}
	} 
	.header-overlay {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: linear-gradient(147.85deg, $purple 32.85%, $orange 99.6%);
		opacity: .8;
	}
}
//.type-page:not(.has-post-thumbnail) {
.type-page.default-page,
.privacy-policy .entry-title {
	font-family: $roboto;
	padding-top: 86px;
	.entry-title {
		margin-bottom: .25em;
	}
}

body {
	#iubenda_policy {
		.iub_content {
			padding: 0;
		}
		ul.styled, ul {
			list-style-type: none;
			padding-left: 0;
			li {
				list-style-type: none;
			}
			
		}
	}
}

.entry-content {
	margin-bottom: 140px;
	> :first-child {
		margin-top: 0;
	}
	img {
		height: auto;
		margin: 1em 0 1em 0;
	}
	ol {
		list-style: unset; 
		padding-left: 20px;
		li::marker {
			color: $orange;
		}
		li {
			margin-bottom: 20px;
			padding-left: 10px;
		}
	}
	> ol {
		list-style-type: auto;
	}
}

// full width / default


// navbar left

.sidebar-left {
	margin-top: -13px;
	.menu li {
		border-bottom: 1px dashed #aca9ad;
		padding-top: 5px;
		padding-bottom: 5px;
		margin-bottom: 0;
		a {
			display: inline-block;
			font-size: 18px;
			line-height: 48px;
			color: $text-darkgrey;
			font-weight: $light;
			margin-left: 6px;
			&:before {
				display: inline-block;
				color: #A6A6A6;
				font-family: $faPro;
				width: 20px;
				margin-right: 10px;
			}
		}
		&.far {
			width: 100%;
			position: relative;
			&:before {
				position: absolute;
				top: 19px;
				color: #A6A6A6;
				font-weight: $faRegular;
			}
			a {
				font-family: $roboto;
				margin-left: 34px;
			}
		}
		&.current-menu-item, &:hover {
			a {
				font-weight: $medium;
			}
			&:before {
				color: $lightorange;
			}
		}
		/*&.fa-document a:before {
			content: "";
		}
		&.fa-compass {
			&:before {
				//content: none;
			}
			a:before {
				//content: "";
			}
		}
		&.fa-heart a:before {
			content: "";
		}
		&.fa-group a:before {
			content: "";
		}
		&.fa-network a:before {
			content: "";
		}*/
	}
}

// background image

.page-template-page-background-image,
.page-template-page-innovation-resources {
	.header {
		position: relative;
		.entry-title {

		}
	}
	.hentry {
		margin-top: 63px;
		.entry-title {
			margin-top: 23px;
			margin-bottom: 10px;
		}
	}
	.entry-excerpt {
		margin-bottom: 90px;
		color: $text-lightgrey-2;
		p {
			font-size: 29px;
			line-height: 34px;
		}
	}
	.entry-image-wrap {
		position: absolute;
		z-index: -1;
		right: 0;
	}
}

.page-template-page-innovation-resources {

	.resources-page-wrap {
		padding-bottom: 90px;
		> div {
			margin-bottom: 30px;
		}
		.card {
			//margin-bottom: $grid-gutter-width;
			height: 100%;
		}
		.card-image {
			min-width: 200px;
			height: 200px;
			&.bg-card-orange {
				background-color: #fddccb;
				position: relative;
				&.bg-card-overlay {
					background-color: transparent;
				}
				.overlay {
					background-color: #f9a87c;
					opacity: .4;
				}
			}
			&.bg-card-purple {
				background-color: #C8BADF;
				position: relative;
				.overlay {
					background-color: #C8BADF;
					opacity: .6;
				}
			}
			.far {
				color: white;
				position: absolute;
				font-size: 66px;
			}
		}
		.card-content {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 200px;
			padding: 10px 25px 10px 35px;
			/*position: absolute;
			top: 50%;
			transform: translate(200px, 0);
			*/
			h2 {
				font-size: 22px;
				margin-top: 0;
			}
			p {
				padding-bottom: 0;
				margin-bottom: 0;
				color: $text-darkgrey;
			}
		}
		.tools-methods {
			.bg-image {
				background-size: 70%;
			}
		}
		.collections {
			.bg-image {
				background-size: 120%; //auto;
			} 
		}
		.icon-play {
			font-weight: $faSolid;
			color: white;
			width: 100%;
		    text-align: center;
		    line-height: 190px;
		    position: absolute;
		    top: 0; left: 0; bottom: 0; right: 0;

			&:before {
				content: '\f167';
				font-size: 66px;
				font-family: $faBrands;
				//transition: zoom .3s;
				//transform: color .3s;
			}
			&:hover:before {
				//zoom: 1.1;
			}
		}
	}
}

// consulting page

.page-id-1933, .page-id-4345 {
	#container {
		background-image: $linear;
		height: 100%;
	}
}
.consulting-wrapper {
	padding-bottom: 80px;
	.entry-title {
		color: white;
		margin-top: 80px;
		max-width: 300px;
	}
	.entry-content {
		svg {
			max-width: 100%;
			height: auto !important;
			margin-top: 18px;
		}
		a {
			text-decoration: none;
		}
		#TopLink:hover g#TriangleTop {
			opacity: .4;
		}
		g {
		  //pointer-events: bounding-box;
		  //opacity: 1;
		}
		g#TriangleTop:hover {
		    //opacity: .4;
		    //filter: opacity(0.5);
		}
	}
	.contact-sales {
		background-color: rgba(0,0,0,.2);
		margin-bottom: 0;
	}
}

@include media-breakpoint-down(md) {
	.page-template-page-innovation-resources {
		.card-direction-row {
			flex-direction: column;
			.card-image {
				height: 200px;
			}
		}
	}

}

@include media-breakpoint-down(sm) {
	.consulting-wrapper .entry-title {
		margin-top: 40px;
	}
	.page-template-page-background-image,
	.page-template-page-innovation-resources {
		.entry-image-wrap {
			opacity: .5;
		}
	}
}

@include media-breakpoint-down(sm) {
	.consulting-wrapper svg {
		height: auto;
    	padding-top: 20px;
    }
}

// my account / login


// error

.error404 {
	#content {
		text-align: center;
		.entry-title {
			margin-top: 63px;
			margin-bottom: 47px;
		}
		p {
			font-size: 26px;
			line-height: 30px;
			margin-top: 37px;
			margin-bottom: 27px;
		}
	}
}
