// tools and methods - single

.cardtool DIV.blog-post-img
 {
	background-color: $text-lightgrey;
}

.single-innovation-tools {
	.hentry {
		margin-top: 26px;
	}
	.entry-excerpt {
		margin-top: 10px;
		p {
			font-size: 29px;
			line-height: 34px;
			letter-spacing: -.38px;
		}
	}
	.entry-image-wrap {
	    //padding-top: 60px;
	    //padding-bottom: 36px;
	    //margin-bottom: 44px;
	    img {
			max-width: 100%;
			height: auto;
	    }
	}
	.download-btn {
		display: inline-block;
		width: 100%;
	    margin-top: 44px;
		&:after {
			content: "»";
			opacity: .5;
			margin-left: .25em;
		}
	}
	.download-wrap {
		padding: 22px 20px;
		margin-top: 44px;
		margin-bottom: 20px;
		.card-title {
			color: $text-purple-2;
			margin-top: 0;
		}
	}
}

@include media-breakpoint-up(lg) {
	.single-innovation-tools {
		.entry-content {
			position: relative;
		}
		.entry-image-wrap {
			margin-top: 38px;
			position: absolute;
		    margin-left: calc(66.66667% - 20px);
		    flex: 0 0 calc(33.33333% + 20px);
	    	max-width: calc(33.33333% + 20px);
	    }
		.download-wrap {
			margin-top: 54px;
		}
	}
}