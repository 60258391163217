#container {
	min-height: calc(100vh - 393px);
}

.grid-2 {
	display: grid;
	grid-template-columns: 50% 50%;
}

.grid-3 {
	display: grid;
	grid-template-columns: 33.3333% 33.3333% 33.3333%;
}

.margin-auto {
	margin-left: auto;
	margin-right: auto;
}

.section-icon-top {
	position: relative;
	width: 100%;
	padding-top: 80px;
	padding-bottom: 80px;
	//&::before,
	&.section-white {
		padding-bottom: 60px;
	}
	.section-icon {
		font-family: "Font Awesome 5 Duotone";
		position: absolute;
		content : "";
		border-radius: 50%;
		transform: translate(-50%, -100px);
		left: 50%;
	    font-size: 26px;
	    line-height: 26px;
	    color: #ACA9AD;
	    padding: 30px;
	    z-index: 2;
		font-weight: $faSolid;
	}
}

.section-darkgrey, .section-purple {
	color: white;
	h1, .h1 {
		color: white;
	}
}

.section-grey {
	background-color: $bg-section-grey;
	margin-top: 20px;
	&::before,
	.section-icon {
		background: $bg-section-grey;
	}
	+ .section-darkgrey, + .section-purple {
		margin-top: 0;
	}
}

.section-darkgrey {
	background-color: $text-grey-palatino;
	&::before,
	.section-icon {
		background: $text-grey-palatino;
	}
	+ .section-grey, + .section-purple {
		margin-top: 0;
	}
}

.section-purple {
	//background-color: $section-purple;
	&::before,
	.section-icon {
		background: $section-purple;
		background-color: $lightpurple;//#634a8c;
		color: white;
	}
	.section-icon {
		background-color: #7b62a4;
	}
	+ .section-grey, + .section-darkgrey {
		margin-top: 0;
	}
	.overlay {
		background-color: $section-purple;
	}
}

.section-orange {
	//background-color: $lightorange;
	margin-top: 100px;
	.fa-swap-opacity .fad:before, .fad.fa-swap-opacity:before, .fad:after {
		opacity: .4;
		opacity: var(--fa-secondary-opacity,.4);
	}
	&::before,
	.section-icon {
		background: $lightorange;
		background: #ce6832;
		color: white;
	}
	/*&::after {
		background: $lightorange;

		position: absolute;
		content : "";
		border-radius: 50%;
		transform: translate(-50%, -50px);
		top: 0;
		left: 50%;
		width: 100px;
		height: 100px;
		display: block;
	}*/
	.overlay {
		background-color: $lightorange;
	}
}

.section-white {
	background-color: white;
	&::before,
	.section-icon {
		background: white;
	}
}

.fa-question-circle {
	&.fad:after {
		opacity: .4;
		opacity: var(--fa-secondary-opacity,.4);
	}
}

.home {
	.section-grey:before,
	.section-grey .section-icon {
		color: $lightorange;
	}
}

.bg-lightpurple {
	background-color: $lightpurple;
	color: white;
	padding-top: 80px;
	padding-bottom: 60px;
	.entry-title {
		color: white;
	}
}
.bg-textpurple {
	background-color: $text-purple;
}

.bg-image {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}
 
.overlay {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	opacity: .85;
}

@include media-breakpoint-up(md) {
	.section-icon-top {
		padding-bottom: 100px;
		&.section-white {
			padding-bottom: 0;
		}
	}
	.section-grey {
		margin-top: 100px;
	}
}
@include media-breakpoint-up(xl) {
	.section-grey {
		margin-top: 128px;
	}
	.section-grey, .section-darkgrey, .section-purple, .section-orange {
		//margin-top: 128px;
	}
	.section-icon-top {
		padding-bottom: 128px;
		&.section-white {
			padding-bottom: 0;
		}
	}
}