// grid 
@include media-breakpoint-between(md, lg) {
	.container, .container-sm, .container-md {
		max-width: calc(100vw - 84px);
	}
}


// modal

.modal {
	.modal-dialog {
		max-width: 1140px;
		height: calc(100% - 3.5rem);
	}
	.modal-content {
		position: absolute;
		background: transparent;
		max-width: 800px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border: none;
	}
	.modal-body {
		background: transparent;
	}
	.modal-header {
		color: white;
		.modal-title {
			color: white;
		}
	}
	.modal-footer {
		border-top: none;
	}
	.close {
		color: white;
		font-size: 50px;
		font-weight: 400;
	}
	.carousel-indicators {
		display: none;
	}
	.carousel {
		.carousel-caption {
			color: white;
			text-align: left;
			min-height: 8em;
		}
	}
}
.search-modal {
	.close {
		display: none;
	}
	.modal-header {
		padding: 0;
		padding-bottom: 10px;
		border: none;
	}
	.modal-body {
		background-color: transparent;
		padding: 0;
	}
	.search-field {
		background: white;
	}
}
.modal {
	.close {
		position: absolute;
	    top: 10px;
	    right: 10px;
	    z-index: 10;
	    background-color: transparent;
	    background: center center no-repeat;
	    background-image: url(../images/da-annoying-x.svg);
	    background-size: cover;
	    width: 50px;
	    height: 50px;
	    cursor: pointer;
	    border: 10px solid transparent;
	}
}
.modal-backdrop {
	//background-color: $text-purple;
	&.show {
		opacity: .8;
	}
}


// carousel 

.carousel {
	.carousel-caption {
		position: static;
		color: $text-darkgrey;
	}
}
.carousel-control-prev-icon, .carousel-control-next-icon {
    margin-bottom: 15%;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
	background-image: none;
	&:before {
		display: block;
		content: "";
		width: 25px;
		height: 25px;
		border: 3px solid white;
		transform: rotate(45deg);
	}
}
.carousel-control-next-icon:before {
	border-left-color: transparent !important;
	border-bottom-color: transparent !important;
}
.carousel-control-prev-icon:before {
	border-right-color: transparent !important;
	border-top-color: transparent !important;
}

// toggle / accordion

.accordion, .faq-accordion {
	.accordion-head {
		a {
			display: block;
			&:before {
				position: absolute;
				content : "–";
				color: black;
				margin-left: -34px;
			}
			&.collapsed:before {
				content: "+";
			}
			&:hover {
				text-decoration: none;
			}
		}
		h5 {
			margin: 0;
		}
	}
}

// cards 

.card {
	box-shadow: $card-shadow;
	border: none;
	border-radius: $card-radius;
	.card-image-left {
		border-top-left-radius: $card-radius;
		border-bottom-left-radius: $card-radius;
	}
}

@include media-breakpoint-up(xl) {
	.carousel-control-prev-icon, .carousel-control-next-icon {
	    margin-bottom: 15%;
	}
}

@include media-breakpoint-up(lg) {
	.col-lg-3-big {
		max-width: calc(25% + 20px);
	    flex: 0 0 calc(25% + 20px);
	}
	.offset-lg-1-less {
		margin-left: calc(8.33333% - 20px);
	}
}


@include media-breakpoint-up(md) {
	.card-direction-row {
		flex-direction: row;
		img, .card-image, .overlay {
			border-top-left-radius: $card-radius;
			border-bottom-left-radius: $card-radius;
		}
		.col-lg-4 {
			padding-left: 0;
		}
	}
}

@include media-breakpoint-down(sm) {
	.container {
		padding-left: 20px;
		padding-right: 20px;
	}
	.carousel-indicators {
		bottom: -10px;
	}
	.modal {
		.carousel {
			max-width:calc(100% - 60px);
			margin: auto;
			.carousel-caption {
				min-height: 240px;
				display: block;
			}
		}
	}
	.close {
		right: 0;
		top: 0;
	}
}