body.author {
	#content {
		padding-bottom: 70px;
	}
	.header {
		padding-top: 20px;
		padding-bottom: 0;
		margin-bottom: 130px;
		overflow: visible;
		.entry-title {
			font-size: 52px;
			margin-top: 70px;
			margin-bottom: 0px;
		} 
		.author-job-title {
			font-size: 31px;
			letter-spacing: -.41px;
			font-weight: $regular;
			line-height: 38px;
			color: white;
			opacity: .6;
			margin-bottom: 40px;
		}
		.post-it {
			margin-bottom: -80px;
		}
		.author-img-wrap {
			//position: relative;
			img {
				max-width: 100%;
				padding-left: 50px;
				&.anonymous-img {
					opacity: .2 !important;
					filter: invert(1);
				}
			}
			.author-motto {
				position: absolute;
				font-family: $palatino;
				font-style: italic;
				font-size: 25px;
				line-height: 30px;
				letter-spacing: -.32px;
				text-align: center;
				color: white;
				background: #896CBB;
				border-radius: 33px;
				width: 280px;
				top: 25px;
				right: -250px;
				padding: 18px 14px;
				&:after {
					content: '';
					position: absolute;
					left: 0;
					top: 50%;
					width: 0;
					height: 0;
					border: 42px solid transparent;
					border-right-color: #896CBB;
					border-left: 0;
					border-bottom: 0;
					//margin-top: 19px;
					margin-left: -32px;
				}
			}
		}
	}
	.entry-content {
		margin-bottom: 80px;
	}
	.publications-title, .writings-title {
		margin-bottom: .7em;
	}
	.author-publications {
		p {
			font-family: $palatino;
			color: $text-grey;
			font-size: 16px;
			line-height: 22px;
			padding-bottom: 0;
			margin-bottom: 20px;
			a { 
				color: inherit;
			}
			i {
				font-style: italic;
			}
		}
	}
	.author-writings {
		article {
			padding-bottom: 22px;
			border-bottom: 1px dashed $hr-grey;
			margin-bottom: 22px;
		}
		.hungryfeed_items {
			h4, h4 + p, figure {
				display: none;
			}
			&:last-of-type article:last-of-type {
				border-bottom: none;
			}
		}
		h3 {
			font-size: 18px;
			a {
				color: $text-darkgrey;
			}
		}
		a {
			display: inline-block;
			margin-bottom: .3rem;
		}
		strong {
			color: $text-darkgrey;
			font-weight: $regular;
		}
		a:hover, a:hover strong {
			color: $purple;
		}
		.excerpt {
			a {
				display: none;
			}
		}
	}
	.sidebar-title {
		padding-bottom: 15px;
		border-bottom: $hr-grey 2px solid;
		margin-bottom: 20px;
		&:before {
			content: "";
			font-family: $faPro;
			font-weight: $faSolid;
			opacity: .5;
			margin-right: 15px;
		}
	}
	.author-superpowers {
		margin-bottom: 100px;
		li {
			display: inline-block;
		}
	}
	.author-contact {
		margin-bottom: 100px;
		.sidebar-title:before {
			content: "";
		}
		li {
			margin-bottom: 20px;
			a {
				color: $orange;
				font-size: 21px;
				font-weight: $regular;
				&:hover {
					color: darken($orange, 10%);
				}
				&:before {
					margin-right: 10px;
					font-family: $faPro;
					font-weight: $faSolid;
				}
				&.author-tel:before {
					content: "";
				}
				&.author-email:before {
					content: "";
				}
				&.social-link:before {
					font-family: $faBrands;
				}
				&.Twitter:before {
					content: "";
				}
				&.LinkedIn:before {
					content: "";
				}
				&.Facebook:before {
					content: "\f082";
				}
				&.SlideShare:before {
					content: "\f1e7";
				}
				&.YouTube:before {
					content: "\f431";
				}
			}
		}
	}
	sidebar {
		position: relative;
		padding-bottom: 210px;
	}
}

.schedule-a-call {
	//position: absolute;
	//bottom: 0;
	width: calc(100% - 20px);
	display: block;
	color: $orange;
	text-align: center;
	padding: 30px;
	svg g {
		fill: $orange;
		path {
			opacity: .7;
		}
		&#Group-25 path:nth-child(1) {
			opacity: 1;
		}

	}
	.icon, .text {
		display: block;
	}
	.icon {
		display: block;
		font-size: 103px;
		line-height: 103px;
		margin: auto;
	}
	.text {
		opacity: .5;
		text-transform: uppercase;
		font-weight: $black;
		&.first-line {
			font-size: 18px;
		}
		&.second-line {
			font-size: 26px;
		}
	}
}

@include media-breakpoint-down(lg) {
	.author-motto {
		display: none;
	}
}