//@import url('../fonts/Roboto/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,300;1,400;1,500&display=swap');

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 300;
	src: url(../../fonts/Roboto/Roboto-LightItalic.ttf) format('truetype');
}
@font-face {
	font-family: 'Roboto';
	font-weight: 300;
	src: url(../../fonts/Roboto/Roboto-Light.ttf) format('truetype');
}
@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 400;
	src: url(../../fonts/Roboto/Roboto-RegularItalic.ttf) format('truetype');
}
@font-face {
	font-family: 'Roboto';
	font-weight: 400;
	src: url(../../fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}
@font-face {
	font-family: 'Roboto';
	font-weight: 500;
	src: url(../../fonts/Roboto/Roboto-Medium.ttf) format('truetype');
}
@font-face {
	font-family: 'Roboto';
	font-weight: 700;
	src: url(../../fonts/Roboto/Roboto-Bold.ttf) format('truetype');
}

html body {
	font-family: 'Roboto', sans-serif;
	font-size: 18px;
	line-height: 1.33;
	color: $text-darkgrey;
	font-weight: $light;
}

HR, HR.wp-block-separator { 
	border: 0;
  height: 1px;
  background: $hr-grey;
	margin: 0.8em 0 1.6em 0;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	font-weight: $medium;
	line-height: 1.1;
	/*margin-top: .5em;*/
	letter-spacing: -0.005em;
	margin-bottom: .5em; //-0.53px;
}

h1, .h1 {
	font-size: 38px;
	color: $text-purple-2; //$purple;
	letter-spacing: -.005em;
	margin-bottom: .25em;
}

h2, .h2 {
	font-size: 34px;
	color: $text-darkgrey;
}

h3, .h3 {
	font-size: 30px;
	color: $text-grey;
}

h4, .h4 {
	font-size: 26px;
	color: $text-grey;
}

h5, .h5 {
	font-size: 20px;
	color: $text-darkgrey;
}

h6, .h6 {
	font-size: 15px;
	color: black;
	text-transform: uppercase;
}

p {
	line-height: 1.5;
	font-weight: $light;
	margin-bottom: 0;
}

li {
	line-height: 1.5;
}


p.has-drop-cap:not(:focus):first-letter {
	font-weight: $light;
	float:left;
	font-size:4.0em;
	line-height:.68;
	margin:.075em .1em 0 0;
	text-transform:uppercase;
}

a {
	color: $text-purple;
	text-decoration: none;
	&:hover {
		color: $orange;
		text-decoration: none;
	}
}

ol, ul {
	padding-left: 0;
}

strong {
	font-weight: $regular;
}

li strong.orange {
	font-weight: $medium;
	color: $black-60;
}

em {
	font-style: italic;
}

.palatino-family {
	font-family: palatino, italic;
	font-style: italic;
}

.palatino {
	font-family: palatino, italic;
	font-style: italic;
	letter-spacing: -.01em;
	font-size: 30px;
	line-height: 28px;
	p {
		font-size: inherit;
		font-weight: inherit;
		line-height: inherit;
		letter-spacing: inherit;
	}
}

.sub-title {
	@extend .palatino;
	font-size: 29px;
    line-height: 34px;
    color: $text-lightgrey-2;
}

.capitalize {
	text-transform: capitalize;
}

::-moz-selection { 
	background-color: $textselection;
	color: $text-darkgrey; 
}
::selection { 
	background-color: $textselection;
	color: $text-darkgrey;
}

.entry-content {
	h1, h2, h3, h4, h5, h6,
	.h1, .h2, .h3, .h4, .h5, .h6 {
		margin-top: 38px;
	}
	p { 
		padding-bottom: 10px;
		margin-bottom: 1rem;
		strong {
			font-weight: $regular;
		}
	}

H3.nomargintop { padding-top: 0; margin-top: 6px; } // unelegant: schneller fix von Jan: wollte nicht so lange rumfrickeln
H1.nomarginbottom { padding-bottom: 0; margin-bottom: 0; }
	
	em {
		opacity: .8;
	}
	
	
	//a:not(.encyclopedia):not(.btn):not(.wp-block-button__link)
	a:not([class]) {
  		@include link-underline(#fff, $orange-dark, $textselection, $purple, $purple);
		}
	
	
	.palatino {
		font-size: 32px;
		line-height: 1.125;
		color: $text-lightgrey-2;
		strong, b {
			font-weight: normal;
			color: black;
		}
	}
}

.entry-title + .palatino {
	@extend .sub-title;
	//font-size: 32px;
	//line-height: 1.125;
	//color: $text-lightgrey-2;

}

p > a.encyclopedia, li > a.encyclopedia, em > a.encyclopedia, i > a.encyclopedia, strong > a.encyclopedia, figcaption > a.encyclopedia, TABLE TD LI > a.encyclopedia {
		cursor: help;
		display: inline-block;   
    background-color: lighten( $bg-grey, 3% );
		color: lighten( $text-darkgrey, 10% );
    border-radius: 2px;
    padding: 0 .25em;
    /* font-family: $roboto;
    font-weight: $regular;
    font-size: $base-font-size * 0.9; */ 
    
    
    &:hover {
    	background-color: lighten( $textselection, 3% );
    	transition: 0.3s;
    }
    
}

code, .code {
    background-color: $bg-grey;
    color: $text-darkgrey;
    font-family: $roboto-mono, monospace;
    font-weight: $regular;
    border-radius: 2;
    padding: 0 .5em;
    font-size: $base-font-size * 0.85;
}

PRE.wp-block-code CODE {
    padding: 10px;
}



.fa-swap-opacity .fad:before, .fad.fa-swap-opacity:before, .fad:after {
	opacity: .7;
	opacity: var(--fa-secondary-opacity,.7);
}

@include media-breakpoint-down(sm) {
	h1, .h1 {
		font-size: 30px;
	}

	h2, .h2 {
		font-size: 28px;
	}

	h3, .h3 {
		font-size: 26px;
	}

	h4, .h4 {
		font-size: 24px;
	}

	/*h5, .h5 {
		font-size: 20px;
		color: $text-darkgrey;
	}

	h6, .h6 {
		font-size: 15px;
		color: black;
		text-transform: uppercase;
	}*/
}



/* Make image caption more beautiful */

figcaption a {
   border:0;
   padding:0;
   background:0;
   text-align:left;
   margin:-1em 0 2em 0;
}

 
figcaption {
	font-size: $base-font-size * 0.675;
	font-weight: $regular;
	/* text-align:right; */
	padding-left:0 !important;
	color: $text-lightgrey-2;
	margin-bottom: 12px;
	line-height: 1.4;
	margin-top: 6px;
}

figcaption:before {

    margin-right: 6px;
	font-family: $faPro;
	font-weight: $faLight;
   	content: "\f302";   	
   	
}

.wp-block-image figcaption {
	margin-bottom: 3em;
}



/* Style der Glossary Tooltips */

.encyclopedia-tooltip .tooltipster-box
{
  padding-right: 0.3em;
  padding-left: 0.3em;
  border-radius: 4px;
}

.encyclopedia-tooltip .tooltipster-content {
  color: white;
  font-size: .8em;
  padding: 15px;
}

/*

.encyclopedia-tooltip.tooltipster-bottom .tooltipster-arrow-background {
  border-color: rgba(116, 66, 166, 0.05);
}

.encyclopedia-tooltip.tooltipster-top .tooltipster-arrow-border {
  border-top-color: $lightpurple;
}

.encyclopedia-tooltip.tooltipster-bottom .tooltipster-arrow-border {
  border-bottom-color: $lightpurple;
}

.encyclopedia-tooltip.tooltipster-left .tooltipster-arrow-border {
  border-left-color: $lightpurple;
}

.encyclopedia-tooltip.tooltipster-right .tooltipster-arrow-border {
  border-right-color: $lightpurple;
}

*/







body::before {
  content: "";
  position: fixed;
  top: -10px;
  left: 0;
  width: 100%;
  height: 10px;
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.5);
  z-index: 100;
}
