// banner

@use "sass:math";

.banner {
	background: $lightorange;
	color: white;
	padding-top: 33px;
	padding-bottom: 35px;
	p {
		font-size: 28px;
		line-height: 34px;
		font-weight: $medium;
		letter-spacing: -.5px;
		text-align: center;
		padding-top: 15px;
		padding-bottom: 15px;
	}
	.sign-up-form {
		text-align: right;
		margin-top: 10px;
		margin-bottom: 10px;
	}
	&.banner-join-expert-network {
		padding-top: 0;
		padding-bottom: 0;
		p {
		}
		.container {
			position: relative;
		}
		.hands {
			width: 250px;
		}
		.sticker-wrap {
			position: absolute;
			width: 208px;
			height: 173px;
			top: 10px;
			right: math.div($grid-gutter-width, 2);
			img {
				height: 100%;
				// filter: grayscale(1) contrast(1);
			}
			span {
				font-size: 16px;
				color: #893C13;
				font-weight: $regular;
				text-align: center;
				margin-top: 20px;
			}
		}
		.btn {
			font-size: 18px;
			text-transform: none;
			letter-spacing: -.75px;
			font-weight: $regular;
			line-height: 1;
			border: none;
			border-radius: 0;
			background-color: #532A14;
			padding: 8px 14px 11px;
		}
	}
}

@include media-breakpoint-up(lg) {
	.banner {
		p {
			display: inline-block;
			width: 49%;
			text-align: left;
		}
		.sign-up-form {
			display: inline-block;
			width: 50%;
		}
		&.banner-join-expert-network {
			p {
				max-width: 370px;
				position: relative;
				bottom: -20px;
				margin-left: 20px;
				margin-right: 40px;
			}
		}
	}
}

@include media-breakpoint-down(md) {
	.banner {
		&.banner-join-expert-network {
			text-align: center;
			.btn {
				margin-bottom: 40px;
			}
		}
	}
}

// footer

#footer {
	font-size: 14px;
	background: $bg-footer;
	color: $text-lightgrey;
	font-weight: $regular;
	padding-top: 66px;
	padding-bottom: 95px;
	a {
		color: white;
		font-weight: $light;
		&.fas {
			font-weight: $faSolid;
		}
	}
	hr {
		border-color: $text-lightgrey;
		margin: 10px 0 20px;
	}
	.footer-widget-area {
		img {
			width: 174px;
			margin-bottom: 5px;
		}
		p {
			margin-bottom: 14px;
		}
	}
	.footer-menu-area {
		line-height: 30px;
		p, li {
			line-height: inherit;
		}
		h5 {
			font-size: 14px;
			font-weight: $regular;
			color: $text-lightgrey;
			margin-top: 0;
		}


		LI A:hover { opacity: 0.8; }

		LI.EN-only A::after {
			content: "";
			background-image:url(/wp-content/themes/codify/dist/images/uk-flag.png);
			background-size: 100% 100%;
			display: inline-block;	
			height: 14px;
			width: 14px;
			position:relative;
			left:6px;
			top:2px;

			filter: grayscale(.9);
			opacity: 0.5;
		}

		LI.EN-only A:hover::after {
			opacity: 1;
			filter: grayscale(0);
		}

	}
	#footer-menu {
		text-align: right;
		ul {
			margin-bottom: 0;
		}
		li {
			display: inline-block;
			&:before {
				content: ' | ';
				padding-left: 5px;
				padding-right: 5px;
			}
			&:first-child:before {
				content: none;
			}
		}
	}
}



#footer-left {
	.widget-area {
		margin-bottom: 25px;
	}
}

.social-media {
	margin-bottom: 30px;
	a {
		//font-style: $faSolid;
		font-size: 24px;
		opacity: .5;
		transition: opacity .3s;
		letter-spacing: 6px;
		&:hover {
			opacity: 1;
		}
		&.fa-solid {
			font-weight: $faSolid;
		}
	}
}

@include media-breakpoint-up(lg) {
	.footer-bottom {
		line-height: 30px;
	}
}

@include media-breakpoint-between(md, lg) {
	#copyright {
		margin-bottom: 15px;
	}
	footer .footer-widget-area {
		.widget-area {
			width: 210px;
		}
		img {
			width: 140px;
		}
		p {
			font-size: 20px;
			line-height: 23px;
		}
	}
}

@include media-breakpoint-down(lg) {
	#footer-left {
		.widget-area {
			float: left;
		}
		.social-media {
			float: right;
		}
	}
	footer {
		padding-bottom: 60px;
		hr {
			margin-bottom: 15px;
		}
	}
	#menu-footer-menu {
		text-align: center;
	}
	#copyright {
		text-align: center;
	}
}

@include media-breakpoint-down(md) {
	#copyright {
		margin-bottom: 30px;
	}
	.home .projects-carousel:not(.testimonials-carousel) {
		.carousel-item {
			display: block;
			float: none;
		}
		.carousel-control-prev, .carousel-control-next {
			display: none;
		}
	}
} 

@include media-breakpoint-down(sm) {
	#footer {
	    padding-top: 40px;
	    padding-bottom: 60px;
		.footer-widget-area {
			.widget-area {
				width: 185px;
				max-width: calc(100% - 98px);
			}
			img {
				width: 135px;
			}
			p {
				font-size: 18px;
			}
		}
	}
	#footer-left .social-media {
		width: 7em;
		text-align: right;
		line-height: 1.2;
		margin-top: 0;
		a {
			margin-bottom: 7px;
		}
	}
	#newsletter-sign-up p {
		font-size: 6vw;
		padding-top: 0;
		padding-bottom: 0;
	}
	.banner.banner-join-expert-network .sticker-wrap {
		top: -40px;
	}
}


@include media-breakpoint-only(lg) {
	.banner.banner-join-expert-network .sticker-wrap {
		top: 180px;
		right: unset;
		left: 50%;
		transform: translate(-50%, 0) rotate(9deg);
	}
}