


// auhtor overview / expert-networt
.page-expert-network {
	.has-post-thumbnail .header {
		background-attachment: unset;
		padding: 0;
		img {
			width: 100%;
			height: auto;
		}
	}
	.content-wrap {
		margin-top: 75px;
	}
}

.expert-feed {
	margin-top: 30px;
	margin-bottom: 150px;
	.expert {
		position: relative;
		border-top-left-radius: $card-radius;
		border-bottom-left-radius: $card-radius;
		font-size: 14px;
		line-height: 18px;
		padding-top: 10px;
		&:hover {
			background: #401D7B;
			z-index: 1;
			.expert-wrap {
				position: absolute;
				background: #401D7B;
				border-radius: $card-radius;
				padding-top: 10px;
				left: 0;
				top: 0;
			}
			.expert-title {
				color: #FEFF6F;
				margin-left: 10px;
			}
			.expert-photo {
				border-color: $lightpurple;
				box-shadow: none;
			    max-width: calc(100% - 20px);
			    margin-left: 10px;
			}
			.expert-info {
				visibility: visible;
			}
			.expertise-wrap {
				display: flex;
			}
		}
		p {
			line-height: 18px;
			margin-bottom: 10px;
		}
	}
	.expert-title {
		font-size: 14px;
		padding-top: 10px;
		margin-bottom: 0;
	}
	.expert-photo {
		max-width: 100%;
		border: 8px solid white;
	}
	.expert-info {
		background: #401D7B;
		color: white;
		visibility: hidden;
		padding: 10px;
		.expert-sub-title {
			color: white;
		}
		//position: absolute;
		//left: 0;
		//top: 0;
		//width: 200%;
		//padding: 10px;
		/*.filler-img {
			height: calc(100px + 50px);
			padding: 10px;
		}*/
	}
	.expert-info.job-role-info {
		position: absolute;
		left: 100%;
	    top: 0;
	    min-width: 100%;
		bottom: 0;
		border-top-right-radius: $card-radius;
		border-bottom-right-radius: $card-radius;
	}
	.expert-superpowers {
		border-bottom-left-radius: $card-radius;
	}
	.expertise-wrap {
		width: 200%;
		border-radius: $card-radius;
		display: none;
		margin: 0;
	}
	.job-role {
		padding-top: 20px;
		font-style: italic;
	}
	.expert-domain {
		padding: 0;
		border-bottom-right-radius: $card-radius;
	}
	.expert-sub-title {
		font-size: 14px;
	}
	.location:before {
		content: "";
		font-family: $faPro;
		margin-right: 5px;
	}
}

.partner-wrap {
	display: grid;
	grid-template-columns: auto auto;
	margin-bottom: 88px;
	a {
		display: block;
		//max-width: 50%;
		//float: left;
		img {
			max-width: 90%;
		}
	}
}

@include media-breakpoint-up(md) {
	.partner-wrap {
		grid-template-columns: auto auto auto auto;
		a {
			//max-width: 25%;
		}
	}
}

@include media-breakpoint-down(md) {
	.expert-info, .expertise-wrap {
		display: none;
	}
} 
