/* Roboto Slab und Mono >> noch einbetten? */


// colors
$purple : #57207E;
$lightpurple : #7350AE;
$text-purple : #563293; 
$text-purple-2 : #3C1779;
$text-purple-3 : #2F1254;
$text-purple-4 : #51259A;
$bg-purple : #8871AF;
$section-purple : #7F5CBA;

$orange-dark : #9E4312;
$orange : #C34400;
$lightorange : #F67733;
$orange-text : #F45500;

$text-darkgrey : #212121;
$text-grey : #4D4D4D;
$text-grey-2 : #878787;
$text-lightgrey : rgba(255,255,255,.5);
$text-lightgrey-2 : #746F77;
$text-grey-palatino : #5C585F;

$bg-grey : #E9E9E9;
$bg-grey-related : #E5E5E5;
$bg-section-grey : #f2f2f2;
$bg-table-grey : #F0F0F0;
$bg-footer : #333333;
$bg-sales : #7F7A86;

$hr-grey : #979797;
$hr-lightgrey : #cbcbcb;
$grey-icon : #ACACAC;
$card-head-grey : #E7E7E7;
$card-head-title : #A7A7A7;
$card-border-grey : #DCDCDC;
 
$white-50 : rgba(255,255,255,.5);
$white-60 : rgba(255,255,255,.6);
$white-80 : rgba(255,255,255,.8);

$black-30 : rgba(0,0,0,.3);
$black-50 : rgba(0,0,0,.5);
$black-60 : rgba(0,0,0,.6);
$black-80 : rgba(0,0,0,.8);

$card-shadow-white : #F4F4F4;

$card-shadow : 0 2px 4px #DCDCDC; //rgba(0,0,0,.5);
$card-radius : 4px;

$tab-inactive : #ECEAEA;

$linear : linear-gradient(115.94deg, #6541A2 47.85%, #F67733 98.52%);


$textselection : #FEFFD7;
$twitter-blue : rgb(29, 161, 242);


// fonts

$palatino : Palatino, 'Palatino Linotype', Georgia, serif;
$roboto : 'Roboto', sans-serif;
$roboto-mono : 'Roboto Mono', sans-serif;
$roboto-slab : 'Roboto Slab', sans-serif;
$faPro : "Font Awesome 5 Pro";
$faBrands : "Font Awesome 5 Brands";


// font weight

$light : 300;
$regular : 400;
$medium : 500;
$bold : 700;
$black : 900;

$faLight : 100;
$faRegular : 400;
$faSolid : 900;

.fa-solid {
	font-weight: $faSolid;
}
.fa-regular {
	font-weight: $faRegular;
}
.fa-light {
	font-weight: $faLight;
}

// font sizes

// <p> font base size >> statt px lieber em @Lana?

$base-font-size : 18px;

$form-label : 16px;
$form-input-normal : 20px;
$form-input-description : 16px;
$checkbox-label : 18px;

// container inner widths
$container-lg-inner : calc(100vw - 84px);
$container-xl-inner : 1120px;


// deco

$text-shadow : 2px 2px 2px rgba(0,0,0,.36);

// Mixins for beautful links


@mixin text-underline-crop($background) {
  text-shadow:  .03em 0 $background, 
                -.03em 0 $background,
                0 .03em $background,
                0 -.03em $background,
                .06em 0 $background,
                -.06em 0 $background,
                .09em 0 $background,
                -.09em 0 $background,
                .12em 0 $background,
                -.12em 0 $background,
                .15em 0 $background,
                -.15em 0 $background;
}

@mixin text-underline($color-bg, $color-text) {
  background-image: linear-gradient(lighten( $color-text, 50% ), lighten( $color-text, 50% ));
  background-size: 1px 1px;
  background-repeat: repeat-x;
  background-position: 0% 95%;
}

@mixin text-selection($selection) {
  &::selection {
    @include text-underline-crop($selection);
    
    background: $selection;
  }

  &::-moz-selection {
    @include text-underline-crop($selection);
    
    background: $selection;
  }
}

@mixin link-underline($background, $text, $selection, $hover, $visited){
  @include text-underline-crop($background);
  @include text-underline($background, $text);
  @include text-selection($selection);

  color: $text;
  text-decoration: none;
  

  *,
  *:after,
  &:after,
  *:before,
  &:before {
    text-shadow: none;
  }

  &:hover {
    color: $hover;
    @include text-underline($background, $hover);
    transition: 0.3s;
  }

  &:visited {
    color: $visited;
    @include text-underline($background, $visited);
  }
}
