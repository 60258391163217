.home {
	.carousel {
		&.home-carousel {
			border-bottom: 10px solid $lightorange;
			.bg-image {
				height: 75vh;
				background-image: $linear;
			}
			.carousel-item > .container {
				position: relative;
				height: 100%;
			}
			.carousel-caption {
				//padding: 62px;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				height: fit-content;
			}
			h2 {
				font-size: 32px;
				color: white;
				text-shadow: -4px 4px 12px rgba(0, 0, 0, 0.4);
			}
			p {
				font-size: 22px;
				line-height: 1.2;
				color: lighten( $purple, 60% ); // #DBC8FA;
				font-weight: $regular;
				text-shadow: -2px 2px 8px rgba(0, 0, 0, 0.65);
				margin: 0 10% 0 10%;
			}
		}
	}
	.hdl-home, .h1 {
		font-size: 45px;
		letter-spacing: -.53px;
	}
	.target-group-teaser {
		margin-top: 92px;
	}
	.target-group-description, .h1 + .palatino {
		color: lighten( $text-grey, 20% );
		font-size: 28px;
		font-family: $palatino;
		margin-bottom: 60px;
		margin-top: 16px;
		&.recent-posts-excerpt {
			margin-bottom: 44px;
		}
	}
	.section-grey {
		header {
			text-align: center;
			.palatino {
				color: $text-lightgrey-2;
				a {
					color: black;
				}
			}
		}
	}
	.blog-feed {
		margin-bottom: 16px;
	}
	.projects-carousel {
		.carousel-item {
			width: calc(100% + 20px);
			.blog-post-img {
				padding-top: 56%;
			}
			.project-post-content {
				padding-top: 27px;
				.h6 {
					margin-bottom: 4px;
				}
				p {
					line-height: 24px;
				}
			}
		}
		.carousel-item.active, .carousel-item-next, .carousel-item-prev {
			display: flex;
		}
		.carousel-control-prev, .carousel-control-next {
			width: auto;
			margin-top: -50px;
		}
		.carousel-control-next {
			//right: -30px;
		}
		.carousel-control-prev {
			//left: -30px;
		}
		.carousel-control-next-icon, .carousel-control-prev-icon {
			background-color: white;
			border-radius: 50%;
			width: 30px;
			height: 30px;
			padding: 10px;
			&:before {
				border-color: $text-grey;
				width: 10px;
				height: 10px;
				padding: 2px;
				/*padding: 5px;
				margin: auto;
				display: inline-table;
				position: relative;
				top: -3px;*/
			}
		}
		.carousel-control-next-icon {
			&:before {
				margin-left: -2px;
			}
		}
		.carousel-control-prev-icon {
			&:before {
				margin-left: 2px;
			}
		}
		.download-btn {
			margin-top: 23px;
		}
	}
	.banner {
		border-radius: $card-radius;
		.container {
			padding-left: 23px;
			padding-right: 18px;
		}
	}
	.testimonials {
		margin-top: 0;
		padding-bottom: 0;
		.carousel-item {
			padding-top: 40px;
			padding-bottom: 28px;
		}
		blockquote {
			color: white;
			display: block;
			text-shadow: 0 2px 4px rgba(0,0,0,.5);
			margin-bottom: 24px;
			&:after {
				border-color: rgba(255,255,255,.28);
				margin-bottom: 5px;
			}
		}
		strong, span {
			letter-spacing: -.21px;
		}
		.quote-photo {
			border-radius: 50%;
			width: 50px;
			height: 50px;
			opacity: .6;
		}
		.quote-text {
			display: inline-grid;
			text-align: left;
			vertical-align: middle;
			margin-left: 15px;
			max-width: calc(100% - 70px);
			span {
				color: rgba(255,255,255,.28);
			}
		}
		.carousel-item > div {
			border-left: 1px dashed rgba(255,255,255,.28);
			padding: 35px;
		}
	}
	.clients-wrap {
		background-color: #e3e3e3;
		padding: 16px;
		height: 100%;
		.clients {
			margin-bottom: 0;
			padding-bottom: 0;
			&.margin-top {
				margin-top: -25px;
			}
		}
		h6 {
			color: $text-lightgrey-2;
		}
		.client {
			object-fit: contain;
		}
		&.home-clients .client {
			max-width: 20%;
		}
		&.home-partners .client {
			max-width: 33%;
		}
	}
	.section-purple {
		&:before {
			content: "";
			position: absolute;
			top: 0; left: 0;
			width: 100%; height: 100%;
			background-image: url(../images/home/banner-purple.png);
			filter: grayscale(100%);
			background-size: inherit;
		}
		//background-image: linear-gradient(black, black), url('../images/home/banner-purple.png');
		//background-blend-mode: saturation;
		//filter: contrast(.35);
		padding-bottom: 50px;
		.overlay {
			opacity: .7;
		}
		img {
			max-width: 100%;
			filter: grayscale(1);
			margin-top: 30px;
		}
		h3.h1 {
			text-align: center;
			position: relative;
			margin-top: 30px;
		}
	}
	.trainings-wrap {
		> .palatino {
			font-size: 34px;
			letter-spacing: -.44px;
			line-height: 44px;
			color: $text-lightgrey-2;
			text-align: center;
			margin-top: 38px;
			margin-bottom: 100px;
		}
		.card-image-left {
			max-width: 360px;
			object-fit: cover;
		}
		.card-direction-row {
			.card-body {
				padding-top: 50px;
				padding-left: 50px;
			}
		}
		.card-body {
			.palatino {
				color: $text-lightgrey-2;
			}
			p {
				font-size: 20px;
				line-height: 28px;
				font-style: normal;
				margin-top: 22px;
				margin-bottom: 22px;
			}
			.class-format {
				color: black;
				opacity: .5;
				font-weight: $medium;
			}
			.h2 {
				//opacity: .8;
				margin-bottom: 11px;
			}
			.card-info {
				.btn {
					float: right;
					//margin-top: 20px;
				}
			}
			.btn {
				padding-top: 3px;
				padding-bottom: 5px;
				.opacity-50 {
					font-size: 120%;
				}
			}
		}
		.col-12 .btn {
			font-size: 24px;
			line-height: 1;
			padding: 12px 20px;
			text-transform: none;
		}
		.col-md-6 .card {
			background-color: $bg-section-grey;
			height: 100%;
		}
	}
	.section-orange {
		background-image: linear-gradient(black, black), url('../images/home/banner-orange.png');
		background-blend-mode: saturation;
		padding-top: 40px;
		padding-bottom: 80px;
		.section-icon {
			transform: translate(-50%, -60px);
		}
		.overlay {
			opacity: .8;
		}
		.row {
			position: relative;
		}
		.telephone {
			h4 {
				color: rgba(0,0,0,.6);
				font-weight: $regular;
				margin-top: 40px;
				margin-bottom: 10px;
			}
			.tel {
				font-size: 32px;
				color: white;
				font-weight: $medium;
			}
		}
		.schedule-wrap {
			position: absolute;
			right: 0;
			zoom: 0;
		}
		.schedule-a-call {
			width: 207px;
			height: 207px;
			background-color: $orange;
			border-radius: 50%;
			transform: rotate(14deg);
			transition: all .6s;
			margin-left: auto;
			&:hover {
				transform: rotate(0);
				background-color: darken($orange, 10%);
			}
			img {
				height: 100%;
			}
			.icon, .text {
				color: white;
				opacity: 1;
			}
			.icon {
				opacity: .6;
				margin-bottom: 5px;
			}
		}
	}
}

@include media-breakpoint-up(xl) {
	.home {
		.section-icon-top {
			.h1 {
				margin-top: 15px;
			}
			&.section-darkgrey .h1 {
				margin-top: 6px;
			}
			&.recent-posts .h1 {
				margin-top: 20px;
			}
		}
		.projects-carousel {
			.carousel-control-next {
				right: -60px;
			}
			.carousel-control-prev {
				left: -60px;
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	.home #projectsCarousel {
		margin-bottom: 76px;
		.project-post-content {
			padding-bottom: 0;
		}
		.blog-post-wrap {
			margin-bottom: 0;
		}
	}
}

@include media-breakpoint-only(lg) {
	.home {
		.projects-carousel {
			.carousel-inner {
				width: calc(100% - 120px);
				margin-left: 60px;
				margin-right: 60px;
			}
		}
	}
}

@include media-breakpoint-down(xl) {
	.home .projects-carousel.testimonials-carousel {
		.carousel-item.active, .carousel-item-next, .carousel-item-prev {
			display: block;
		}
		.quote-info, .carousel-item > div {
			border-left: none; 
		}
	}
}

@include media-breakpoint-up(md) {
	.home {
		.carousel.home-carousel {
			h2 {
				font-size: 56px;
				line-height: 56px;
				letter-spacing: -.73px;
			}
			p {
				font-size: 28px;
				letter-spacing: -.37px;
			}
		}
		.h1 {
			margin-bottom: 0;
			+ .palatino {
				color: lighten( $text-grey, 20% );
				font-size: 34px;
				letter-spacing: -.44px;
				line-height: 40px;
			}
		}
		.blog-feed {
			margin-top: 40px;
		}
		article .palatino {
			//font-size: 32px;
		}
		//.trainings-wrap > .palatino {
		.trainings-wrap .card {
			.palatino {
				font-size: 26px;
				letter-spacing: -.34px;
				line-height: 32px;
			}
		}
		.section-orange .telephone {
			h4 {
				font-size: 47px; 
				letter-spacing: -1.18px;
				line-height: 55px;
			}
			.tel {
				font-size: 54px;
				line-height: 55px;
			}
		}
		.target-group-description, .h1 + .palatino, .h1 + .sub-title {
			font-size: 34px;
		}
	}
}

@include media-breakpoint-up(lg) {
	.home {
		.trainings-wrap {
			.blog-post-wrap {
				.blog-post-content {
					padding: 50px;
				}
				&.col-md-6 {
					.blog-post-content {
						padding: 42px 50px 46px;
					}
				}
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	.home {
		.carousel.home-carousel .bg-image {
			height: 720px;
		}
	}
}

@include media-breakpoint-down(lg) {
	.home {
		.blog-feed, .trainings-wrap {
			.btn:not(.download-btn) {
				display: none;
			}
		}
	}
	/*.home {
		.trainings-wrap {
			.card-direction-row .card-body {
				padding-bottom: 30px;
				.btn {
					margin-top: 30px;
				}
			}
		}
	}*/
}

@include media-breakpoint-down(md) {
	.home {
		.trainings-wrap {
			.card-image-left {
				max-width: 40%;
			}
			.card-direction-row .card-body {
				padding-top: 30px;
				padding-left: 30px;
			}
		}
		.section-purple img {
			display: block;
			max-width: 50%;
			margin: 30px auto 10px;
		}
		.section-orange .telephone {
			h4 {
				font-size: 44px;
			}
			.tel {
				font-size: 50px;
			}
		}
		.recent-posts .col-lg-7 {
			margin-bottom: 20px;
		}
		// remove carousel on mobile !
	}
}

@include media-breakpoint-down(sm) {
	.home {
		.h1 {
			font-size: 32px;
		}
		.trainings-wrap {
			.card-image-left {
				max-width: 100%;
				height: 300px;
			}
			.card-direction-row .card-body {
				padding: 20px;
			}
		}
		.section-orange {
			padding-bottom: 40px;
			.schedule-wrap {
				position: relative;
				transform: scale(0.6) rotate(14deg);
				margin: auto;
			}
		}
		.section-orange .telephone {
			text-align: center;
			h4 {
				font-size: 8.95vw;
				margin-bottom: 0;
			}
			.tel {
				font-size: 10.8vw;
			}
		}
	}
}