
// single

.trainingtags {
	.current-cat {
		opacity: 1;
	}
}

.single-training {
	img {
		max-width: 100%;
	}
	.nav-tabs.row {
		margin-left: 0;
		margin-right: 0;
	}
}

.single-training, .single-training .has-post-thumbnail {
	.header {
		padding-top: 30px;
		padding-bottom: 0;
		margin-bottom: 0;
	}
	.course-duration {
		margin-bottom: 20px;
		text-shadow: -2px 2px 8px rgba(0, 0, 0, 0.65);
	}
	.entry-title {
		font-size: 62px;
		letter-spacing: -1.29px;
		line-height: 100%;
		margin-bottom: 16px;
		text-shadow: -4px 4px 12px rgba(0, 0, 0, 0.4);
	}
	.entry-excerpt {
		margin-right: 40px;
		text-shadow: -2px 2px 8px rgba(0, 0, 0, 0.65);
	}
	&.single {
		.has-post-thumbnail .entry-excerpt, .entry-excerpt {
			margin-top: 0;
			margin-bottom: 25px;
		}
	}
	.entry-meta {
		padding-bottom: 114px;
		text-shadow: -2px 2px 8px rgba(0, 0, 0, 0.65);
	}
	.tab {
		margin-top: -80px;
		font-size: 24px;
		line-height: 30px;
		font-weight: $medium;
		background: white;
		padding: 25px 40px 24px;
		background: $tab-inactive;
		color: $text-lightgrey-2;
		border: none;
		&.active {
			background: white;
			color: black;
		}
		&:hover {
			color: black;
		}
	}
}
.single-training {
	.entry-content-wrap {
		margin-top: 80px;
	}
	.individuals-false .entry-content-wrap {
		margin-top: 78px;
	}
	.fade:not(.show) {
		//display: none;
	}
	.video-call {
		p {
			font-size: 16px;
			padding-bottom: 10px;
		}
	}
	h4 {
		margin-bottom: 33px;
	}
}

.teaser-video-wrap {
	text-align: center;
    margin-bottom: 80px;
    margin-left: 20px;
    margin-right: -20px;
	.teaser-video, .sticker-wrap {
		cursor: pointer;
	}
	.teaser-video {
		position: relative;
		max-width: 274px;
		max-height: 274px;
		width: 100%;
    	padding-top: 100%;
		border-radius: 50%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		background-blend-mode: luminosity;
    	background-color: #C2777F;
		box-shadow: 0 0 0 10px rgba(246,119,51,.3);
		&:after {
			content: "";
			font-family: $faPro;
			font-size: 68px;
			font-weight: $faSolid;
			color: white;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			transition: .3s color;
			text-shadow: -4px 4px 16px rgba(0, 0, 0, 0.45);
		}
		&:hover:after {
			color: $lightorange;
		}
	}
}
.sticker-wrap {
	position: relative;
	display: inline-block;
	font-size: 20px;
	line-height: 19px;
	color: #522209;
	font-weight: $bold;
	width: 165px;
	transform: rotate(9deg);
	margin-top: -40px;
	img {
		max-width: 100%;
	}
	span {
		//color: #522209;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: calc(100% - 20px);
	}
}
#videoModal {
	iframe {
	    margin: auto;
	    display: block;
	}
}

.impressions-wrap {
	margin-bottom: 78px;
	.impression {
		margin-bottom: 20px;
	}
	
	.impression-cursor {
		// https://codyhouse.co/nuggets/custom-cursor > mal später machen
		cursor:url(.../images/zoom-in.png), -moz-zoom-in;
	}		
	
	
	img {
		max-width: 100%;
		cursor: pointer;
	}
}

//.next-dates, 
.card-info {
	.card-info-cell {
		border-bottom: 1px dashed $card-border-grey;
		margin-bottom: 20px;
		&:last-of-type {
			border-bottom: none;
			margin-bottom: 0px;
		}
	}
	//.next-dates-head, .next-dates-body,
	//.next-dates-head,
	.card-info-head {
		font-size: 22px;
		font-weight: $medium;
		color: $card-head-title;
		padding: 20px;
		background: $card-head-grey;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		padding: 12px 20px;
	}
	//.next-dates-body,
	.card-info-body {
		padding: 20px;
		.month {
			padding-top: 10px;
		}
		.date-time {
			font-size: 14px;
			color: $text-grey-2;
			padding-bottom: 30px;
		}
		.btn {
			margin-top: 10px;
			margin-bottom: 10px;
			.opacity-50 {
				font-size: 120%;
				line-height: 100%
			}
		}
	}
}

//
.related-trainings {
	p, a {
		font-size: 16px;
		line-height: 18px;
		color: $text-darkgrey;
	}
	p {
		margin-bottom: 10px;
	}
	a {
		display: block;
		padding-top: 21px;
		padding-bottom: 21px;
		border-bottom: 1px dotted $hr-grey;
		&:last-of-type {
			border: none;
		}
	}
}

.target-group {
	.h1, .target-group-description {
		text-align: center;
	}
	.target-group-description {
		margin-bottom: 40px;
	}
}
.single-training {
	.target-group {
		margin-top: 40px;
		.h1 {
			margin-bottom: 28px;
		}
		.target-group-description {
			margin-bottom: 50px;
		}
	}
}
.persona {
	.card {
		padding: 25px;
		height: 100%;
	}
	.persona-head {
		margin-bottom: 23px;
		min-height: 78px;
		h5 {
			display: inline-block;
			float: left;
			font-size: 22px;
			line-height: 26px;
			margin-top: 0;
			width: calc(100% - 78px - 20px);
		}
		img {
			display: inline-block;
			width: 78px;
			height: 78px;
			text-align: right;
			margin-left: 20px;
		}
	}
	.jobs-to-be-done {
		list-style-type: none;
		li {
			border-bottom: 1px dotted $hr-lightgrey;
			padding-top: 14px;
			padding-bottom: 14px;
			&:first-of-type {
				padding-top: 0;
			}
			&:last-of-type {
				border-bottom: none;
			}
		}
	}
}

.testimonials {
	text-align: center;
	margin-top: 45px;
	padding-bottom: 45px;
	.carousel-item {
		padding-top: 50px;
		padding-bottom: 100px;
	}
	blockquote {
		font-family: $palatino;
		color: black;
		font-size: 27px;
		line-height: 36px;
		letter-spacing: -.32px;
		font-style: italic;
		margin-bottom: 10px;
		p {
			padding-bottom: 0;
			margin-bottom: 0;
			line-height: inherit;
		}
		p:before {
			content: '"';
		}
		p:after {
			content: '"';
		}
		&:after {
			content: "";
			display: block;
			border-top: 1px solid $lightorange;
			width: 46px;
			margin: 20px auto;
			//margin-left: auto;
			//margin-right: auto;
		}
	}
	.name, .title {
		display: block;
		letter-spacing: -.21px;
	}
	.name {
		color: black;
		font-weight: $regular;
	}
	.title {
		color: rgba(0,0,0,.4);
	}
}

.carousel-indicators {
	//z-index: 3;
	li {
		background-color: #D8D8D8;
		width: 14px;
		height: 14px;
		border-radius: 50%;
		margin-left: 8px;
		margin-right: 8px;
		&.active {
			background-color: $text-lightgrey-2;
		}
	}
}

.next-level {
	margin-top: 85px;
	.h3 {
		margin-bottom: 20px;
	}
	p {
		line-height: 24px;
	}
}

.agenda {
	padding-top: 62px;
	.section-icon {
		transform: translate(-50%, -90px);
	}
	.h1 {
		margin-bottom: .5em;
	}
}
.accordion {
	.accordion-head, .accordion-body {
		background: white;
		border: 1px solid $grey-icon;
		border-top: none;
	}
	.accordion-head {
		&:first-of-type {
			border-top: 1px solid $grey-icon;
		}
		a {
			/*display: block;*/
			display: inline-block;
			color: $orange-text;
			font-weight: $medium;
			font-size: 24px;
			line-height: 33px;
			letter-spacing: -0.383333px;
			padding: 18px 26px 18px 54px;
		}
		
		A span.weekfocus {
			color: $text-grey;
			font-weight: $light;
			font-size: 24px;
			display: inline-block;
		}
	}
	.accordion-body {
		padding: 40px 26px 40px 54px;
		h6 {
			margin-top: 0;
			padding-top: 18px;
		}
		p {
		    padding-bottom: 10px;
		    /* margin-bottom: 1rem; */
		}
		p:last-of-type, ul {
			/* padding-bottom: 18px; */
		}
		ul {
			margin-bottom: 0;
		}
	}
}

.pie-chart {
	background: #D5D5D5;
	border-radius: $card-border-radius;
	text-align: center;
	padding: 40px 20%;
	.pie-chart-title {
		color: $text-purple-3;
		font-size: 22px;
		line-height: 25px;
		margin-bottom: 40px;
	}
	/*.svg-wrap {
		position: relative;
		.icon {
			color: white;
			font-weight: $faSolid;
			position: absolute;
			opacity: .5;
		}
	}*/
	svg {
		height: 150px; // the contents will scale to fit because of viewBox
	    border: 10px solid white;
	    border-radius: 50%;
	    box-shadow: $card-shadow;
	    box-shadow: 0 2px 4px rgba(0,0,0,.21);
	    text {
	    	font-family: $faPro;
	    	color: red;
	    	font-size: 100px;
	    	fill: blue;
	    }
	}
	.pie-chart-description {
		font-size: 16px;
		line-height: 18px;
		margin-top: 30px;
		margin-bottom: 22px;
	}
	.pie-chart-infos {
		text-align: left;
		list-style-type: none;
		margin-top: 20px;
		margin-bottom: 0;
		li {
			display: block;
			font-size: 12px;
			text-transform: uppercase;
			font-weight: $medium;
			line-height: 14px;
			margin-bottom: 12px;
			text-align: center;
			/*&:before {
				display: inline-block;
				content: "";
				width: 1em;
				height: 1em;
				border-radius: 4px;
				margin-right: 10px;
				margin-bottom: -.1em;
				font-family: $faPro;
				font-weight: $faSolid;
			}*/
			&:last-of-type {
				margin-bottom: 0;
			}
			&.Doing {
				color: #A45DEA;
				//&:before {
					//content: '';
					//background: #A45DEA;
				//}
			}
			&.Theory {
				color: #7442A6;
				//&:before {
					//content: '';
					//background: #7442A6;
				//}
			}
			&.Cases {
				color: #FF6E2C;
				//&:before {
					//content: '';
					//background: #FF6E2C;
				//}
			}
			&.Reflection {
				color: #E2591A;
				//&:before {
					//content: '';
					//background: #E2591A;
				//}
			}
		}
	}
}

.packages {
	padding-top: 90px;
	.section-icon {
		transform: translate(-50%, -120px);
	}
}
.package-feed {
	.row {
		margin-left: -15px;
		margin-right: -15px;
		.col-lg-4 {
			padding-right: 15px;
			padding-left: 15px;
		}
	}
	.package-item {
		&:before {
			content: "\f00c";
			font-family: $faPro;
			color: $grey-icon;
			font-size: 38px;
			font-weight: bold;
			position: absolute;
			right: -10px;
			top: 10px;
		}
		background: white;
		padding: 24px 20px;
		height: calc(100% - 20px);
		margin-bottom: $grid-gutter-width;
		.package-title {
			font-size: 16px;
			line-height: 1.3;
			color: $orange-text;
			margin-top: 18px;
			margin-bottom: 8px;
			a {
				color: $orange-text;
				text-decoration: underline;
			}
		}
		.package-icon {
			color: $lightorange;
			display: block;
			font-size: 48px;
			opacity: .6;
		}
		.package-description {
			color: $text-lightgrey-2;
			font-size: 14px;
			line-height: 20px;
		}
	} 
}

.faq-wrap {
	.accordion-head {
		a {
			padding: 12px 14px 12px 24px;
			&:before {
				color: $orange;
				margin-left: -24px;
			}
		}
		h5 {
			margin: 0;
			border-bottom: 1px dashed $hr-grey;
			a, a:hover {
				color: $text-darkgrey;
			}
		}
	}
	.accordion-body {
		padding-left: 24px;
		padding-right: 24px;
		padding-bottom: 30px;
		p {
			padding-top: 17px;
			padding-bottom: 10px;
		}
	}
}

.individuals-true {
	.faq-wrap {
		.public-courses {
			display: none;
		}
	}
}

.individuals-true {
	&.individuals-hidden {
		.join-training {
			display: none;
		}
		.faq-wrap {
			.public-courses {
				display: block;
			}
			.internal-courses {
				display: none;
			}
		}
	}
	&:not(.individuals-hidden) {
		.contact-sales {
			display: none;
		}
	}
}
#nav-teams {
	.entry-content {
		margin-bottom: 0;
		p {
			font-weight: $regular;
			margin-bottom: 14px;
			&.lightorange {
				margin-top: 20px;
			}
		}
	}
}

.clients-title {
	padding-top: 65px;
	border-top: 1px dashed $hr-grey;
	margin-top: 60px;
}

.clients {
	text-align: justify;
	//padding-bottom: 65px;
	//border-bottom: 1px dashed $hr-grey;
	//margin-bottom: 60px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	h4 {
		text-align: left;
	}
	img {
		max-width: 136px;
		&::after{content:""; display: inline-block; width: 100%; height: 0;}
	}
}

.single-training {
	.clients {
		display: grid;
		grid-template-columns: repeat(3, auto);
		margin-bottom: 40px;
	}
}

.join-training {
	text-align: center;
	padding-top: 50px;
	padding-bottom: 50px;
	.h1 {
		text-align: center;
		margin-bottom: 28px;
	}
	.btn {
		font-size: 28px;
		text-transform: none;
		letter-spacing: -.33px;
		line-height: 60px;
		min-width: 100%;
		padding: 0 50px;
	}
}

.contact-sales {
	background: $bg-sales;
	//padding: 43px 38px 48px;
	margin-top: 100px;
	margin-bottom: 100px;
	margin-left: 0;
	margin-right: 0;
	.icon {
		position: relative;
		font-size: 60px;
		font-weight: $faSolid;
		color: white;
		opacity: .6;
		padding: 20px;
		text-align: center;
		line-height: 70px;
		&:hover {
			color: white;
			opacity: 1;
			text-decoration: none;
		}
		span {
			position: absolute;
			font-family: $roboto;
			font-size: 11px;
			font-weight: $medium;
			text-transform: uppercase;
			bottom: -25px;
			width: 100%;
			text-align: center;
			left: 0;
		}
	}
	.telephone {
		padding: 30px;
	}
	h6, a {
		color: white;
		//display: inline-block;
	}
	.tel {
		font-size: 40px;
		letter-spacing: -1.14px;
		line-height: 45px;
		font-weight: $medium;
	}
}
.single-training .contact-sales {
    margin-top: 16px;
    //margin-bottom: 100px;
}

.individuals-false {
	.contact-sales {
		margin-top: 100px;
	}
}


@include media-breakpoint-down(lg) {
	.teaser-video-wrap .teaser-video {
		margin-top: 54px;
	}
	.persona {
		margin-bottom: $grid-gutter-width;
	}
	.clients img {
		max-width: 100%;
	}
}

@include media-breakpoint-up(md) {
	.join-training {
		padding-top: 100px;
		padding-bottom: 84px;
		.btn {
			min-width: 380px;
		}
	}	
	#nav-teams {
		.entry-content {
			p {
				font-size: 28px;
				line-height: 32px;
			}
		}
	}
	.persona .persona-head h5 {
		font-size: 18px;
		line-height: 21px;
	}
	.faq-wrap {
		.accordion-head a, .accordion-body {
			max-width: 90%;
		}
	}
}

@include media-breakpoint-up(lg) {
	.contact-sales {
		//height: 172px;
		//padding: 43px 38px 48px;
		.telephone {
			padding: 41px 38px 42px;
		}
		.tel {
			font-size: 54px;
			letter-spacing: -1.14px;
			line-height: 63px;
		}
		.icon {
			line-height: 132px;
		}
	}
	.single-training {
		.clients {
			display: grid;
			grid-template-columns: repeat(7, auto);
		}
	}
}

@include media-breakpoint-up(xl) {
	.single-training, .single-training .has-post-thumbnail {
		&.single {
			.has-post-thumbnail .entry-excerpt, .entry-excerpt {
				margin-bottom: 53px;
			}
		}
	}
	.teaser-video-wrap .teaser-video {
		margin-top: 100px;
	}
	.single.single-training .has-post-thumbnail .header .post-meta {
		padding-top: 115px;
	}
	.teaser-video-wrap .teaser-video {
		margin-top: 150px;
	}
	.pie-chart {
		margin-left: 75px;
		padding: 30px 20px 40px;
	}
	.home .target-group {
		//margin-bottom: 128px;
	}
	.single-training {
		.target-group {
			margin-top: 108px;
		}
	}
	.single-training .course-duration, .single-training .has-post-thumbnail .course-duration {
		margin-bottom: 8px;
	}
	.faq-wrap {
		.accordion-head a, .accordion-body {
			max-width: 80%;
		}
	}
}

@include media-breakpoint-down(md) {
	.single-training, .single-training .has-post-thumbnail {
		.entry-meta {
			padding-bottom: 30px;
		}
		.tab {
			font-size: 20px;
			padding: 25px 20px 24px;
		}
		.entry-content-wrap {
			margin-bottom: 70px;
		}
		.header {
			overflow: hidden;
		}
	}
	.teaser-video-wrap {
		//margin: auto;
		margin-bottom: 30px;
	}
	.pie-chart {
		margin-top: 30px;
		.pie-chart-infos {
			width: fit-content;
			margin-left: auto;
			margin-right: auto;
			li {
				font-size: 14px;
			}
		}
	}
	.testimonials .carousel-item {
		padding-top: 20px;
		padding-bottom: 50px;
	}
	.individuals-true {
		.header, &.has-post-thumbnail .header {
			padding-bottom: 100px;
		}
	}
}

@include media-breakpoint-down(sm) {
	.single-training, .single-training .has-post-thumbnail {
		.entry-content-wrap {
			margin-top: 20px;
		}
		.entry-excerpt {
			margin-right: 0;
			margin-bottom: 26px
		}
		.tab {
			padding: 15px;
			line-height: 24px;
			text-align: center;
		}
		.header {
			margin-bottom: 40px;
		}
	}
	.teaser-video-wrap {
		margin-left: auto;
		margin-right: auto;
		.teaser-video {
			margin-top: 0;
		}
	}
	.single-training {
		.nav-tabs.row {
			margin-left: -20px;
			margin-right: -20px;
		}
	}

	#nav-teams {
		.entry-content {
			margin-bottom: 30px;
			p:first-of-type {
				margin-top: 20px;
			}
		}
	}
	.persona {
		margin-bottom: 40px;
	}
	.pie-chart {
		padding: 30px;
		.pie-chart-infos {
			width: auto;
		}
	}
	.contact-sales {
		.icon {
			line-height: 2em; //calc(50vw - 50px);
			line-height: 1em;
			margin-bottom: 20px;
		}
	}
	.individuals-false .contact-sales {
		margin: 10px 0 40px;
	}
	.next-level {
		margin-top: 30px;
	}
	.next-dates, .related-trainings {
		.card {
			margin-bottom: 50px;
		}
	}
	.testimonials blockquote {
		font-size: 20px;
		line-height: 24px;
	}

	.package-feed {
		.package-item {
			margin-bottom: 40px;
			.package-title {
			}
			.package-description {
				color: $text-lightgrey-2;
				font-size: 16px;
				line-height: 22px;
			}
		}
	}
}
 
@include media-breakpoint-down(xs) {
	.contact-sales {
		.tel {
			font-size: 9.5vw;
		}
	}
	.join-training .btn {
		padding: 0;
	}
}

@include media-breakpoint-only(md) {
	.single-training {
		.clients {
		    grid-template-columns: repeat(4, auto);
		}
	}
}


/*
@include media-breakpoint-only(md) {
	.target-group {
		.persona {
			&:nth-child(3) {
				background: $bg-table-grey;
			}
			&:nth-child(4) {
				background: white;
			}
		}
	}
}
*/