@use "sass:math";

.type-page.default-page {
	&.post-251 { //, &.post-264 {
		.entry-title {
			margin-top: 3px;
			margin-left: calc(25% + 10px);
			margin-bottom: 47px;
		}
	}
}
.page-id-251 {
	&:not(.logged-in) {
		.entry-title {
			margin-left: 0;
			margin-bottom: 47px;
		}
	}
}
.entry-content .wpuf-dashboard-container,
.wpuf-dashboard-container {
	font-family: $roboto;
	margin-left: math.div(-$grid-gutter-width, 2);
	margin-right: math.div(-$grid-gutter-width, 2);
	.wpuf-dashboard-navigation, .wpuf-dashboard-content {
		padding-left: math.div($grid-gutter-width, 2);
		padding-right: math.div($grid-gutter-width, 2);
	}
	.wpuf-dashboard-navigation {
		width: 25%;
		ul {
			width: 190px;
		}
		.wpuf-menu-item {
			border-bottom: 1px dashed #D5D4D6;
			padding-top: 5px;
			padding-bottom: 5px;
			margin-bottom: 0;
			&:last-of-type {
				border-bottom: none;
			}
			a {
				color: $text-darkgrey;
				font-weight: $light;
				line-height: 48px;
				text-shadow: none;
				background-image: none;
				&:before {
					font-family: $faPro;
					color: $text-lightgrey-2;
					margin-right: 8px;
				}
				&:hover {
					background: none;
				}
			}
			&.active a, a:hover {
				font-weight: $medium;
				color: inherit !important;
				&:before {
					color: $lightorange;
					font-weight: $faLight;
				}
			}
		}
		.dashboard a:before {
			content: "";
		}
		.post a:before {
			content: "\f044";
		}
		.edit-profile a:before {
			content: "";
		}
		.invoices a:before {
			content: "";
		}
	}
	.wpuf-dashboard-content {
		width: 75%;
		display: block;
		&.dashboard {
			> p:first-of-type {
				margin-top: 1rem;
			}
		}
		.wpuf-section-title {
			color: $text-purple;
			text-align: left;
		}
		form.wpuf-form-layout1 {
			@extend .card;
			.section_break {
				display: none;
			}
		}
		.wpuf-login-form li, 
		ul.wpuf-form li {
			padding: 22px;
			margin-bottom: 0;
			.wpuf-section-wrap {
				border: none;
			}
			.wpuf-label {
				color: $text-grey-palatino;
				font-size: 16px;
				line-height: 1.2;
				font-weight: $medium;
				margin-bottom: 0;
			}
			.wpuf-fields {
				input, input[value], input[placeholder] {
					font-weight: $regular;
					color: $text-darkgrey;
					font-size: 22px;
					line-height: 18px;
				}
				input[type=text], input[type=email], input[type=password] {
					font-family: $roboto;
					font-weight: $regular;
					font-size: $form-input-normal;
					padding: 11px 20px 12px;
					border-radius: 8px;
					border: #979797;
					box-shadow: inset -3px 3px 9px rgba(0,0,0,.1);
				}
				input[type=checkbox], input[type=radio] {
					width: auto;
				}
			}
			.wpuf-help {
				font-family: $roboto;
				font-size: $form-input-description;
				color: $text-lightgrey-2;
				opacity: .7;
				line-height: 1.2;
				margin-top: 10px;
				display: inline;
			}
			&.wpuf-submit {
				margin-top: 20px;
				margin-bottom: 12px;
				&:before {
					color: white;
					content: '';
					font-family: $faPro;
					font-weight: $faSolid;
					font-size: 16px;
					position: absolute;
				    padding: 6px 15px;
				    opacity: .6;
				}
				input[type=submit] {
					text-transform: uppercase;
					text-shadow: $text-shadow;
					line-height: 1;
					width: calc(50% - 70px);
					text-align: left;
					//background: url(../images/save.svg) no-repeat scroll 12px 8px;

					//-webkit-mask-image: url(../images/save.svg);
					//mask-image: url(../images/save.svg);
					//background-size: 18px;
					background-color: $lightorange;
					border: 1px solid $orange-text;
					box-shadow: inset -3px 3px 22px $orange;
					padding: 10px 20px 10px 40px;
					border-radius: 8px;
					margin-right: 6px;
				}
			}
		}
	}
}

html body {
	.wpuf-error {
		margin: 20px 0;
	}
	ul.wpuf-form li .wpuf-fields .wpuf-help {
		font-family: $roboto;
		font-size: $form-input-description;
		color: $text-lightgrey-2;
		opacity: .7;
		line-height: 1.2;
		margin-top: 10px;
		display: inline;
	}
}

body ul.wpuf-form .wpuf-field-columns {
	&.has-columns-2 .wpuf-column-field-inner-columns .wpuf-column .wpuf-column-inner-fields {
		&.column-1 {
			padding-right: 35px !important;
		}
		&.column-2 {
			padding-left: 35px;
			padding-right: 0 !important;
		}
	}
} 
.wpuf-column-field-inner-columns .wpuf-column .wpuf-column-inner-fields ul.wpuf-column-fields li {
	//margin-right: 70px;
}
html body ul.wpuf-form.form-label-above li .wpuf-label {
	margin-bottom: 0;
}

html body ul.wpuf-form {
	li {
		margin-bottom: 6px;
	}
	.wpuf-el.password + li .wpuf-label {
		display: none;
	}
	.pass-strength-result {
		font-size: 12px;
		font-family: Roboto;
		font-weight: bold;
		text-transform: uppercase;
		color: rgba(0,0,0,.6);
		border-radius: 8px;
		&.strong {
			border: 1px solid #78e236 !important;	
			background: #8dff44 !important;
		}
	}
	.wpuf-el.recaptcha .wpuf-label {
		display: none;
	}
}

.wpuf-form-add .wpuf-submit + li {
	display: none;
}

.wpuf-form-layout1 {
	//padding: 20px;
	.wpuf-form {
		//@extend .card;
		.wpuf-label {
			color: $text-grey-palatino;
			font-family: $roboto;
			font-size: 16px;
			font-weight: $medium;
			label {
				font: inherit;
				color: inherit;
			}
		}
		.wpuf-fields {
			input, input[value], input[placeholder] {
				font-weight: $regular;
				color: $text-darkgrey;
				font-size: 22px;
				line-height: 18px;
			}
			input[type=text], input[type=email], input[type=password] {
				padding: 11px 20px 12px;
				border-radius: 8px;
				border: #979797;
				box-shadow: inset -3px 3px 9px rgba(0,0,0,.1);
			}
			input[type=checkbox], input[type=radio] {
				width: auto;
				margin-right: 10px;
			}
			&[data-type="radio"] label, .wpuf-checkbox-inline {
				font-size: $checkbox-label;
			}
		}
		.wpuf-help {
			font-family: $roboto;
			font-size: $form-input-description;
			color: $text-lightgrey-2;
			opacity: .7;
		}
		&.wpuf-submit {
			&:before {
				content: '';
				font-family: $faPro;
				font-weight: $faSolid;
				font-size: 20px;
				position: absolute;
			    padding: 6px 13px;
			    filter: invert(1) contrast(200%);
			    opacity: .95;
			}
			input[type=submit] {
				text-transform: uppercase;
				text-shadow: $text-shadow;
				padding: 10px 20px 10px 40px;
				border-radius: 8px;
				margin-right: 6px;
				line-height: 1;
				//background: url(../images/save.svg) no-repeat scroll 12px 8px;

				//-webkit-mask-image: url(../images/save.svg);
				//mask-image: url(../images/save.svg);
				//background-size: 18px;
				background-color: $lightorange;
				border: 1px solid $orange-text;
				box-shadow: inset -3px 3px 22px $orange;
			}
		}
	}
}
.page-id-264 {
	.wpuf-form-layout1 {
		.wpuf-form {
			//padding: 20px !important;
		}
	}
}
.post-3515,
.post-3631,
.post-264 {
	.wpuf-form-layout1 {
		padding: 0;
		.wpuf-multistep-progressbar {
			display: none;
		}
		.custom_html_1 {
			padding: 0;
		}
		.sub-title {
			margin-top: 0;
		}
		.pass-strength-result {
			width: 100%;
		}
		li {
			padding-left: 0;
			padding-right: 0;
		}
	}
	ul.wpuf-form .wpuf-field-columns .wpuf-column-field-inner-columns .wpuf-column .wpuf-column-inner-fields ul.wpuf-column-fields li {
		margin-right: 10px;
	}
	hr {
		border-style: dotted;
		margin-bottom: 0;
	}
	.wpuf-submit, .wpuf-el.recaptcha {
		display: inline-block;
		width: 50%;
	}
	.wpuf-el.recaptcha {
		padding-bottom: 0;
		margin-bottom: 0;
	}
	.wpuf-submit {
		float: right;
	}
	input[type=submit] {
		text-transform: uppercase;
		color: white;
		background: linear-gradient(180deg, $lightorange, $orange);
		font-size: 18px;
		text-transform: uppercase;
		color: white;
		background: linear-gradient(180deg, #F67733, #C34400);
		width: 100%;
		padding: 20px;
		//width: 440px;
		//padding: 10px;
		border-radius: 4px;
		border: none;
		&:before {
		}
	}
	.wpuf-el.custom_html {
		font-size: 15px;
		color: $text-grey;
	}
}
.wpuf-form {
	li.sub-form-head {
		background-color: #e2e0e0;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		padding: 30px;
		margin-top: 50px;
		margin-bottom: 0;
		margin-left: -30px;
		margin-right: -30px;
		.wpuf-label label {
			color: black;
		}
		+ .section_break, + .section_break + script + .wpuf-el {
			background-color: #f3f2f2;
			padding: 40px 30px 20px;
			margin-bottom: 0;
			border: 1px solid #dcdbdb;
			margin-left: -30px;
			margin-right: -30px;
		}
		+ .section_break {
			border-bottom: none;
			.wpuf-section-wrap {
				margin: 0;
				border-bottom: none;
				.wpuf-section-title {
					color: $text-purple;
					font-size: 18px;
					text-align: left;
				} 
				.wpuf-section-details {
					color: #8c888f;
					font-size: 18px;
					font-style: italic;
					text-align: left;
				}
			}
			+ script + .wpuf-el {
				padding-top: 0;
				padding-bottom: 0;
				border-top: none;
				border-bottom-left-radius: 4px;
				border-bottom-right-radius: 4px;
				margin-bottom: 6px;
				.wpuf-column-field-inner-columns .wpuf-column-fields li {
					margin-bottom: 40px;
				}
				label {
					color: #48434c;
				}
			}
		}
	}
	.sub-form-body {
		background-color: #f3f2f2;
	}
	select {
		margin-left: 1px;
	    /*padding: 10px;
	    background: linear-gradient(180deg, white, lightgrey);
	    max-width: 100%;
	    border-radius: 4px;
	    border-color: grey;
	    box-shadow: 1px 1px 3px grey;*/
	}
}
body ul.wpuf-form li .wpuf-label label .required {
	color: inherit;
}
html body .wpuf-dashboard-container .wpuf-dashboard-navigation a {
	font-family: $roboto;
}

body {
	.wpuf-form-add .wpuf-form .wpuf-multistep-progressbar ul.wpuf-step-wizard li.active-step, .wpuf-form-add .wpuf-form .wpuf-multistep-progressbar .ui-widget-header {
		background: $lightorange;
	}
	.wpuf-form-add .wpuf-form .wpuf-multistep-progressbar ul.wpuf-step-wizard li.active-step::after {
		border-left-color: $lightorange;
	}
}

.login {
	form.wpuf-login-form, form#lostpasswordform {
		label {		    
			color: $text-lightgrey-2;
		    font-size: $form-label;
		    font-weight: 500;
		}
		.forgetmenot {
			label {
				font-weight: $regular;
				margin-left: 10px;
			}
		}
		input, input[value], input[placeholder] {
			font-weight: $regular;
			color: $text-darkgrey;
			font-size: 22px;
			line-height: 18px;
		}
		input[type=text], input[type=email], input[type=password] {
			padding: 11px 20px 12px;
			border-radius: 8px;
			border: #979797;
			box-shadow: inset -3px 3px 9px rgba(0,0,0,.1);
			max-width: 400px;
			width: 100%;
		}
		.submit {
			input {
				text-transform: uppercase;
				text-shadow: $text-shadow;
				padding: 10px 20px; // 10px 40px;
				border-radius: 8px;
				line-height: 1;
				max-width: 400px;
				width: 100%;
			}
			input[type=submit] {
				font-weight: $bold;
				color: white;
				background-color: $lightorange;
				border: 1px solid $orange-text;
				box-shadow: inset -3px 3px 22px $orange;
			}
		}
	}
}
#wpuf-login-form {
	a {
		text-shadow: none;
		background-image: none;
	}
}


@include media-breakpoint-down(md) {
	body ul.wpuf-form .wpuf-field-columns .wpuf-column-field-inner-columns .wpuf-column .wpuf-column-inner-fields {
		width: 100% !important;
	}
	body ul.wpuf-form .wpuf-field-columns.has-columns-2 .wpuf-column-field-inner-columns .wpuf-column .wpuf-column-inner-fields {
		&.column-1, &.column-2 {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}
		&.column-1 {
			margin-bottom: 20px;
		}
	}
}
