
// archive training
.archive {
	.header {
		padding-top: 44px;
		padding-bottom: 44px;
	}
	.trainingcategory {
		margin-bottom: 26px;
		.description {
			color: $text-grey;
			display: inline;
			&.title {
				text-transform: capitalize;
			}
			&.description {
				font-weight: $regular;
				opacity: .4;
			}
		}
	}
	.archive-image {
		img {
			max-width: 100%;
		    position: absolute;
		    bottom: -100px;
		}
	}
	.archive-feed {
		padding-top: 80px;
		padding-bottom: 40px;
		&:nth-child(odd) {
			background: rgba(0,0,0,.05);
		}
		article {
			margin-bottom: 45px;
			margin-left: 0;
			margin-right: 0;
		}
		.blog-post-img {
			height: 100%;
			min-height: 300px;
			background: center right no-repeat;
			background-color: $purple;
			background-size: cover;
			border-top-left-radius: $card-radius;
			border-bottom-left-radius: $card-radius;
			.overlay {
				background: linear-gradient(115.94deg, #6541A2 47.85%, #F67733 98.52%);
				opacity: .9;
				position: absolute;
				top: 0;
				left: 10px;
				right: 10px;
				bottom: 0;
				border-top-left-radius: $card-radius;
				border-bottom-left-radius: $card-radius;
			}
		}
		.blog-post-content {
			position: relative;
			padding: 25px 40px 70px 55px;
			.course-duration {
				color: $orange;
				font-size: 14px;
				line-height: 38px;
				letter-spacing: -.18px;
				font-weight: $medium;
			}
			.palatino {
				color: $text-lightgrey-2;
				margin-bottom: 30px;
			}
			.class-format {
				position: absolute;
				bottom: 45px;
				font-weight: $medium;
				color: black;
				opacity: .5;
			}
			.btn {
				position: absolute;
				bottom: 35px;
				right: 40px;
				font-size: 20px;
				line-height: 1;
				text-transform: none;
				padding: 12px 19px;
			}
		}
	}
}

.archive-training, .tax-trainingtag {
	.header {
		.entry-title {
			line-height: 1;
			margin-bottom: 0;
		}
	}
}

.tax-trainingcategory {
	.sub-title p {
		color: white;
		opacity: .7;
	}
}

.course-duration:before {
	content: "";
	font-family: $faPro;
	margin-right: 5px;
}

// innovation tools and methods

.post-type-archive-innovation-tools {
	.header {
		padding-bottom: 27px;
	}
	.hentry {
		position: relative;
		padding-top: 40px;
	}
	.nl-sign-up-wrap {
		padding-top: 44px;
		padding-bottom: 44px;
		position: absolute;
		right: 0;
		.card { 
			text-align: center;
			img {
				max-width: 130px;
				margin: 20px auto 0;
			}
			.card-text {
				margin: 18px;
				margin-bottom: 36px;
			}
			.sign-up-form {
				margin-left: 18px;
				margin-right: 18px;
				margin-bottom: 40px;
			}
		}
	}
	.entry-content {
		margin-bottom: 0;
		padding-right: 60px;
	}
	.blog-post-wrap {
		.blog-post-img {
			background-color: $text-grey-2;
			background-size: contain;
			border: 20px solid $text-grey-2;
			padding-top: calc(59% - 40px);
			/*&:before {
				content: "";
				display: block;
				margin-top: 60%;
			}*/
		}
		.blog-post-content {
			padding: 37px 30px;
			.blog-post-title {
				color: $text-purple-3;
			}
			.card-text {
				color: $text-grey-palatino;
				font-size: 18px;
				line-height: 24px;
				letter-spacing: 0;
			}
		}
	}
}

@include media-breakpoint-down(lg) {
	.archive {
		.archive-feed {
			.blog-post-content .btn {
				display: none;
			}
		}
	}
}


@include media-breakpoint-down(md) {
	.post-type-archive-training {
		.entry-title {
			//text-align: center;
			margin-bottom: -40px;
		}
	}
	.archive {
		.archive-image {
			margin: auto;
			img {
				position: relative;
			}
		}
		.archive-feed {
			.blog-post-img, .blog-post-img .overlay {
			    border-top-right-radius: $card-radius;
			    border-bottom-left-radius: 0;
			}
		}
	}
	.package-feed {
		margin-bottom: 30px;
	}
	.next-level {
		margin-top: 30px;
	}
	.post-type-archive-innovation-tools .nl-sign-up-wrap {
		position: static;
	}
}

@include media-breakpoint-down(sm) {
	.archive .archive-feed .blog-post-content {
		padding: 20px 35px 30px;
		.class-format, .btn {
			position: static;
			margin-top: 20px;
			width: 100%;
		}
	}
	.post-type-archive-training .entry-title {
		text-align: center;
	}
	.archive .archive-image {
		max-height: 70px;
		img {
			bottom: -60px;
		}
	}
}