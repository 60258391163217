/** 
Table Styles
**/


.entry-content :not(.entry-content #pgcalendar) {  /* START .entry-content */


	TABLE {
		border:rgba(224, 224, 224, 0.3) 6px solid;
		margin: 0 0 1em 0;
		background: lighten( $text-grey, 90% );
	}


	TABLE THEAD {
		border-bottom: inherit;
	}


	TABLE TH P {
		margin:0;
		padding:0;
	}

	TABLE TH {
		padding: 12px 12px 12px 12px;
		/* border-top:1px solid #fafafa;
		border-bottom:1px solid #e0e0e0; */
	  	border-left: 1px solid #fff;
	  	border-right: 1px solid #e0e0e0;
		font-weight: $regular;
	  	vertical-align: top;
	  	text-align: left;
		background: lighten( $text-grey, 60% );
		
		p {
			font-weight: $regular;
		}
		
	}

	TABLE th:first-child{
		border-left:#ccc 1px solid;
	}

	TABLE tr:first-child th:first-child{
		
	}

	TABLE tr:first-child th:last-child{
		
	}

	TABLE tr {
		text-align: left;
	}

	TR:nth-child(odd) {
	    background: lighten( $text-grey, 67% );
	}

	TR:nth-child(odd) TD A {
	    @include link-underline(lighten( $text-grey, 67% ), $orange-dark, $textselection, $purple, $purple);
	}



	TABLE tr td:first-child {
		text-align: left;
		/* border-left: 0; */
	}

	TABLE tr td {
		padding:12px;
		border-top: 1px solid #ffffff;
		border-bottom:1px solid opacify(rgba(224, 224, 224, 1), 0.2);
		border-left: 1px solid opacify(rgba(224, 224, 224, 1), 0.2);
		// border-left: 1px solid rgba(224, 224, 224, 1);
		border-right: 1px solid #ffffff;
		vertical-align: top;
		background-color:none;
		/* break long words */
		/* hyphens: auto; */
		/* background: #fafafa;
		background: -webkit-gradient(linear, left top, left bottom, from(#fbfbfb), to(#fafafa));
		background: -moz-linear-gradient(top,  #fbfbfb,  #fafafa); */
	}


						
	TABLE tr:last-child td{}

	TABLE tr:last-child td:first-child{}

	TABLE tr:last-child td:last-child{}

	TABLE tr:hover td{ background: #f2f2f2; }

	TABLE tr td.rowspan, table tr:hover td.rowspan{
		background: #fff;
		border-right: 1px solid #e0e0e0;
	}
					
	TABLE td span.verticaltext {

		/* writing-mode:tb-rl;
		-webkit-transform:rotate(90deg);
		-moz-transform:rotate(90deg);
		-o-transform: rotate(90deg);
		white-space:nowrap;
		display:block;
		bottom:0;
		width:20px;
		height:20px;	*/
		
		transform: rotate(90deg);
		transform-origin: left top 0;	
					
					}

	TABLE a:link { }
	TABLE a:visited { }
	TABLE a:active,
	TABLE a:hover { }


	FIGURE.text-sm {
		font-size:0.8em;
	}

.entry-content FIGURE.hr-li UL {
	  
}


} /* END .entry-content */


body .entry-content FIGURE.hr-li ul:not(.wpuf-form) li {
	padding-left: 0;
	&:before {
		content: none;
		/*LANA ich krieg den Bibbus vorm Text nicht weg ... HELP!*/
	}
}

.entry-content FIGURE.hr-li LI {
	  padding: .3em 0 .8em 0;
	  line-height: 1.3em;
	  border-bottom: 1px dotted #aca9ad;
	  display:inline-block;
	  
				
				&:first-child{
					padding-top: 0;
				}
	  
	  			&:last-child{
					border-bottom: 0;
				}
}

/** 
DT-Blogpost
**/

.entry-content FIGURE.design-thinking {

		TABLE { border:#ccc 2px solid; }
		TD { hyphens: none; }


		/* Understand: #1881A4; Observe: #D8848E; POV: #3AB76F; Ideate: #FAB514; Prototype: #E77A0C; Test: #C02C49; */
		TH:nth-child(2) { background: #1881A4 !important; color: #fff !important; }		
		TR TD:nth-child(2) { background: lighten(#1881A4, 50%) !important; }
		TH:nth-child(3) { background: #D8848E !important; color: $black-60 !important; }		
		TR TD:nth-child(3) { background: lighten(#D8848E, 30%) !important; }
		TH:nth-child(4) { background: #3AB76F !important; color: $black-60 !important; }		
		TR TD:nth-child(4) { background: lighten(#3AB76F, 50%) !important; }
		TH:nth-child(5) { background: #FAB514 !important; color: $black-60 !important; }		
		TR TD:nth-child(5) { background: lighten(#FAB514, 40%) !important; }
		TH:nth-child(6) { background: #E77A0C !important; color: #fff !important; }		
		TR TD:nth-child(6) { background: lighten(#E77A0C, 40%) !important; }
		TH:nth-child(7) { background: #C02C49 !important; color: #fff !important; }		
		TR TD:nth-child(7) { background: lighten(#C02C49, 50%) !important; }


		TH, TD { font-size:0.75em; }
			

}


/* !!! Nasty hack: is there a more elegant way @Lana? */
DIV+FIGCAPTION {
    margin-top: -20px;
}

FIGURE#DTmeasurewhowhat { overflow-x:auto; }


.entry-content #DTmeasurewhowhat TABLE {

		TD { 
			vertical-align : middle; 
			// width: 1%;
    	}

    	TH {
    		text-align: center;
    	}

		TH:nth-child(1) { text-align: left; width: 10%; }
		TD:nth-child(2) { white-space: nowrap; }		
		TH:nth-child(2) { text-align: left; width: 30%; }
		TD:nth-child(2) { white-space: nowrap; }	
		TH:nth-child(3),
		TH:nth-child(4),
		TH:nth-child(5),
		TH:nth-child(6),
		TH:nth-child(7) { width: 12%; }		
}



.entry-content #dtactivitymetricsimportant TABLE {
	
	border:#ccc 2px solid !important;
	TR, TD { background: lighten( $text-grey, 90% ) !important; }

}


FIGURE.wp-block-table TD LI::marker { 

				/* margin-bottom: .8em; // 1.33em;
				padding-left: 18px; */


				&:before {  
					
					content: "◦";
					/* content: "☑️"; */

					/* display: inline-block;
					
					opacity: .5;
					height: 0; 
					border-top: 4px solid transparent;
					border-bottom: 4px solid transparent;

					border-left: 4px solid $lightorange;
					margin-left: -18px;
					margin-right: 12px; */
				}



 } 

FIGURE.wp-block-table TABLE TD SPAN.p { margin-bottom:1em; display:block; }



DIV.wp-block-columns {

	margin-bottom:0;

	.tablestyle {

		padding-bottom: 15px;
		margin-top:10px;


		.tablestyle-h {
			margin: -30px -30px 20px -30px;
			padding: 15px 30px 15px 30px;
			border-radius: $card-radius $card-radius 0 0;
 			background: $black-30; // $bg-section-grey;
 			color: #fff;
 			font-size: .95em;

		}

		H6 { 
			text-transform: none; 
			font-size: .9em;
			margin-bottom:.5em;
			}

		HR {
			opacity:.35;
			margin:1.6em 0 1.6em 0;
		}

	}

	.tablestyle:not(:first-child) {
    	margin-left: 15px;
	}

}



/** 
DataTables Styles
**/

.dataTables_paginate {

	UL.pagination LI::before { 
		content: none; 
	}

	.page-item.active .page-link {
		color: #fff;
		background-color: $orange;
		border-color: $orange;
	}

	.page-link {
		color: $orange-dark;
		background-color: #fff;
	}
	
}

.dataTables_info { font-size:0.8em; }


/** 
Pretty Google Calendar Styles
**/

.pgcal-branding { display: none; }

