@use "sass:math";

// forms

body, .banner, .password-protected-form, .sign-up-form, .registration-form {
	form {	
		//width: 490px;
		input[type=email], input[type=submit], 
		input[type=password], .btn,
		input[type=text] {
			font-size: 14px; 
			line-height: 22px;
			padding: 10px 10px;
			border-color: transparent;
		}
		input[type=email], input[type=password],
		input[type=text] {
			background: rgba(255,255,255,.8);
			border-top-left-radius: 8px;
			border-bottom-left-radius: 8px;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			width: calc(100% - 91px);
		}
		input[type=submit], .btn {
			color: white;
			background: #9E4312;
			border-top-right-radius: 8px;
			border-bottom-right-radius: 8px;
			//width: 90px;
		} 
		.btn {
			background-color: $lightorange;
			//border: 2px solid $lightorange;
			//margin-top: -4px;
			border-bottom-left-radius: 0;
			border-top-left-radius: 0;
			border: none;
			box-shadow: inset -3px 3px 22px $orange;
			&:hover {
				background-color: darken($lightorange, 10%);
			}
		}
	}
}


.sign-up-form-small, .registration-form {
	form {
		input[type=email], input[type=text], input[type=submit] {
			font-size: 18px;
			//line-height: 40px;
		}
		input[type=email], input[type=text] {
			background: rgba(0,0,0,.06);
	    width: calc(100% - 121px);
		}
		input[type=submit] {
			background-color: $lightorange;
			width: 120px;
			margin-left: -5px;
			&:hover {
				background-color: darken($lightorange, 10%);
			}
		}
	}
}


.rss-feed-form {
	form {
		input[type=text] {
			width: calc(100% - 57px);
		}
		input[type=submit] {
			width: 56px;
		}
	}
}


.password-protected-form {
	.pw-input {
		border-radius: $card-radius;
	}
	&.password-protected-form-regular {
		form {
			input[type=password] {
				width: 484px;
				padding: 11px 20px 12px;
				border-radius: 8px;
				border: #979797;
				box-shadow: inset -3px 3px 9px rgba(0,0,0,.1);
			}
			.btn {
				border-radius: 8px;
				margin-left: 36px;
				padding: 11px 20px 12px;
				margin-top: 0;
			}
		} 
	}
	&.password-protected-form-video {
		form {
			width: 285px;
			input[type=password] {
				width: calc(100% - 126px);
				border: none;
			}
			.pw-input-wrap {
				box-shadow: -10px 10px 12px rgba(0, 0, 0, 0.2);
			}
		}
	}
	&.copy-password {
		width: 400px;
		input[type=text] {
			width: calc(100% - 51px);
		}
		.btn {
			min-width: 50px;
			width: 50px;
			padding: 6.5px;
			i {
				margin-right: 0;
			}
		}
	}
}
.banner, .password-protected-form-video {
	form {
		input[type=email] {
			width: calc(100% - 81px);
		}
	}
}
.password-protected-video-content .password-protected-form.copy-password .btn i {
	padding-top: 9.5px;
	padding-bottom: 10px;
}


@include media-breakpoint-up(md) {
	.banner, .password-protected-form-video {
		p {
			padding-top: 0;
		}
		form {
			input[type=email], input[type=submit],
			input[type=password], .btn {
				font-size: 20px;
				line-height: 40px;
				padding: 10px;
			}
			input[type=email] {
				width: calc(100% - 121px);
			}
			input[type=submit], .btn {
				width: 120px;
				margin-left: -4px;
				margin-top: -2px;
			}
			input[type=password] {
				width: calc(100% - 126px);
			}
		}
	}
}

@include media-breakpoint-down(sm) {
	.banner form input[type=submit], .banner form .btn, .password-protected-form form input[type=submit], .password-protected-form form .btn, .sign-up-form form input[type=submit], .sign-up-form form .btn, .registration-form form input[type=submit], .registration-form form .btn {
		margin-left: -4px;
	}
}


