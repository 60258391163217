* {
	outline: none;
}


.post-edit-link {
	display: none;
}

.btn, .btn-cta, .wp-block-button__link, .comment-reply-link, .form-submit #submit {
	display: inline-block;
	font-size: 16px;
	font-weight: $medium;
	text-align: center;
	text-transform: uppercase;
	color: white;
	background: $orange;
	border: 1px solid $lightorange;
	border-radius: 4px;
	min-width: 130px;
	padding: 10px 18px;
	&:hover {
		color: white;
		background: $lightorange;
		text-decoration: none;
	}
	i {
		color: inherit;
		opacity: .7;
		margin-right: 8px;
	}
}

.btn-big {
	text-transform: none;
	font-size: 28px;
	letter-spacing: -.33px;
}
.btn-black {
	background-color: black;
	border-color: rgba(255,255,255,.6);
	width: auto;
}

.small {
	font-size: 14px;//76%;
	font-weight: $light;
	line-height: 18px;
}

.orange {
	color: $orange;
}
a.orange {
	display: inline-block;
	&:hover {
		color: $purple;
	}
}
.lightorange {
	color: $lightorange;
}
.purple {
	color: $text-purple-4;
}
.red {
	color: indianred;
}

.darken {
	background: rgba(0,0,0,.1);
}
.darken-more {
	background: rgba(0,0,0,.2);
}

.opacity-50 {
	opacity: .5;
}

.border-orange {
	border-color: $lightorange;
}

.icon {
	font-family: $faPro;
}

.icon-user-headset:before {
	content: "\f82d";
}

.icon-mail:before {
	content: "";
}

.icon-play:before {
	content: "";
}

.icon-download:before {
	content: "";
}

.icon-overlay {
	font-size: 60px;
    font-weight: $faSolid;
    color: white;
    opacity: .6;
    &:hover {
    	opacity: 1;
    }
}

.icon-search::before {
	content: "";
}
.text-sm {
	font-size: .8em;
	table, th, td {
		font-size: inherit;
	}
}

.post-it {
	color: black;
	background-color: #FEFF6F;
	box-shadow: -4px 4px 17px rgba(0,0,0,.18);
	padding: 35px 50px;
}


.tags {
	width: 100%;
    list-style-type: none;
    padding: 0;
    margin-bottom: 20px;
    margin-top: 0;
	li { 
		display: inline-block;
		margin-right: 0.75em;
		margin-bottom: 0.75em;
		a {
			display: block;
		    background-color: $orange;
			color: #fff;
			font-size: 16px;
			line-height: 1;
    		font-weight: $medium;
    		text-decoration: none;
			padding: 8px 11px;
			border-radius: 4px;
		    &:hover {
		      background-color: darken($orange, 10%);
		    }
		    &:before {
				content: "#";
				color: hsla(0, 0%, 100%, 0.8);
				margin-right: .15em;
			}
		}
	}
}

.missing {
	background: indianred;
	padding: 50px;
	width: 100%;
	text-align: center;
	* {
		color: white;
	}
}

// balance text

.balance-text {
  text-wrap: balance;  /* Apply (proposed) CSS style */
}

.password-protected-form {
	.pw-input {
		border-radius: $card-radius;
	}
	&.password-protected-form-regular {
		form {
			input[type=password] {
				width: 484px;
				padding: 11px 20px 12px;
				border-radius: 8px;
				border: #979797;
				box-shadow: inset -3px 3px 9px rgba(0,0,0,.1);
			}
			.btn {
				border-radius: 8px;
				margin-left: 36px;
				padding: 11px 20px 12px;
				margin-top: 0;
			}
		} 
	}
	&.password-protected-form-video {
		form {
			width: 285px;
			input[type=password] {
				width: calc(100% - 126px);
				border: none;
			}
			.pw-input-wrap {
				box-shadow: -10px 10px 12px rgba(0, 0, 0, 0.2);
			}
		}
	}
	&.copy-password {
		width: 400px;
		input[type=text] {
			width: calc(100% - 51px);
		}
		.btn {
			min-width: 50px;
			width: 50px;
			padding: 6.5px;
			i {
				margin-right: 0;
			}
		}
	}
}
.password-protected-video-content .password-protected-form.copy-password .btn i {
		padding-top: 9.5px;
		padding-bottom: 10px;
	}

.sign-up-form-small, .registration-form {
	form {
		input[type=email], input[type=text], input[type=submit] {
			font-size: 18px;
			//line-height: 40px;
		}
		input[type=email], input[type=text] {
			background: rgba(0,0,0,.06);
	    width: calc(100% - 121px);
		}
		input[type=submit] {
			background-color: $lightorange;
			width: 120px;
			margin-left: -5px;
			&:hover {
				background-color: darken($lightorange, 10%);
			}
		}
	}
}


.rss-feed-form {
	form {
		input[type=text] {
			width: calc(100% - 57px);
		}
		input[type=submit] {
			width: 56px;
		}
	}
}


@include media-breakpoint-up(md) {
	.banner, .password-protected-form-video {
		p {
			padding-top: 0;
		}
		form {
			input[type=email], input[type=submit],
			input[type=password], .btn {
				font-size: 20px;
				line-height: 40px;
				padding: 10px;
			}
			input[type=email] {
				width: calc(100% - 121px);
			}
			input[type=submit], .btn {
				width: 120px;
				margin-left: -4px;
				margin-top: -2px;
			}
			input[type=password] {
				width: calc(100% - 126px);
			}
		}
	}
}

@include media-breakpoint-down(sm) {
	.banner form input[type=submit], .banner form .btn, .password-protected-form form input[type=submit], .password-protected-form form .btn, .sign-up-form form input[type=submit], .sign-up-form form .btn, .registration-form form input[type=submit], .registration-form form .btn {
		margin-left: -4px;
	}
}





