
#post-categories, .progress-container {
	position: fixed;
	width: 100%;
	z-index: 20;
}

#post-categories {
	top: 52px;
}

.progress-container {
	top: 52px;
}

.single-post, .blog, .category, .tag {
	#content {
		padding-top: 52px;
	}
}

// categories & tags
.post-categories, .post-tags {
	h3 {
		margin-top: 35px;
		margin-bottom: 10px;
	}
	a {
		display: inline-block;
		color: white;
		border-radius: 7px;
		box-shadow: -2px 2px 4px rgba(60, 23, 121, 0.29693);
		margin-right: 12px;
		margin-bottom: 8px;
		&:hover {
			text-decoration: none;
		}
	}
}

.post-categories {
	background: $lightpurple;
	padding-top: 8px;
	li {
		list-style-type: none;
		display: inline-block;
		&:hover, &.active, &.current-cat {
			a {
				opacity: 1;
			}
		}
	}
	a {
		font-size: 12px;
		line-height: 14px;
		background: $purple;
		opacity: .3;
		padding: 5px 8px;
		border: 1px solid #9D80CD;
	}
}

.post-tags {
	margin-bottom: 20px;
	a {
		font-size: 14px;
		line-height: 18px;
		font-weight: $medium;
		background: $lightorange;
		opacity: .7;
		padding: 8px 11.5px;
		border: 1px solid rgba(195, 67, 0, .42); //#C34400;
		box-shadow: -2px 2px 4px rgba(195, 67, 0, .42);
		&:hover, &.active, &.current-cat {
			opacity: 1;
		}
	}
}

// blog overview & related posts

.blog, .blog-feed {
	&.related-posts {
		background: $bg-grey-related;
		padding-bottom: 50px;
		.blog-post-wrap > a {
			display: block;
			background: white;
			height: 100%;
		}
	}
	.project-post-content {
		padding-top: 30px;
		padding-bottom: 30px;
	}
	a:hover {
		text-decoration: none;
	}
	.related-posts-title {
		margin-top: 0;
		padding-top: 55px;
	}
	.blog-post-wrap {
		margin-bottom: 30px;
		/*a {
			border-top-left-radius: $card-radius;
			border-top-right-radius: $card-radius;
		}*/
	}
	.blog-post-img, .video-post-img-protected {
		padding-top: 59%;
		background-color: $bg-footer;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		//border-top-left-radius: $card-radius;
		//border-top-right-radius: $card-radius;
	}
	.blog-post-content {
		position: relative;
		//background: $card-shadow-white;
		padding: 30px;
		padding-bottom: 45px;
		.reading-time {
			position: absolute;
			color: $orange-text;
			background: white;
			font-size: 14px;
			line-height: 30px;
			top: -29px;
			left: 0;
			padding-right: 10px;
			padding-left: 10px;
		}
		h5 {
			color: $text-purple-3;
			line-height: 24px;
			margin-top: 7px;
		}
		p {
			font-size: $base-font-size;
			font-style: italic;
			color: $text-grey-palatino;
			line-height: 24px;
			opacity: .7;
			min-height: 80px;
		}
	}
}

.blog-post-wrap.card, 
.entry-link.card,
.featured-post-wrap,
.related-posts .blog-post-wrap a,
.blog-feed .card,
.resources-page-wrap .card {
	//box-shadow: 0 1px 2px rgba(0,0,0,0.15);
	position: relative;
	transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
	&:after {
		content: '';
		position: absolute;
		border-radius: inherit;
		box-shadow: 0 5px 15px rgba(0,0,0,0.1);
		opacity: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		-webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
		transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
	}
	&:hover {
		-webkit-transform: scale(1.02, 1.02);
		transform: scale(1.02, 1.02);
		&:after {
			opacity: 1;
		}
	}
}

// blog overview - index.php
.blog, .category, .tag {
	.header {
		background: $text-purple;
		color: white;
		padding-top: 34px;
		padding-bottom: 35px;
		.entry-title {
			color: white;
			margin: 0;
			a {
				color: white;
				&:hover {
					opacity: .8;
				}
			}
			span {
				opacity: .6;
			}
		}
		.search-form-wrap {
			text-align: right;
			//padding-top: 28px;
			//padding-bottom: 28px;
		}
	}
	.featured-post-wrap-link {
		color: white;
		&:hover {
			text-decoration: none;
			color: white;
		}
	}
	.featured-post-wrap {
		background-color: $bg-footer;
		color: white;
		margin: 30px 0;
		border-radius: $card-radius;
		.featured-image {
			background-color: $bg-footer;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			border-top-left-radius: $card-radius;
			border-bottom-left-radius: $card-radius;
		}
		.featured-content {
			padding: 53px 60px 60px 40px;
			.entry-title {
				color: white;
				margin-bottom: 25px;
			}
			p {
				font-size: 28px;
				line-height: 34px;
				opacity: .6;
			}
		}
	}
	.encyclopedia-prefix-filters {
		display: none;
	}
}
body.blog {
	.cat-item-all a {
		opacity: 1;
	}
}


.blog-feed {
	margin-top: 60px;
	margin-bottom: 60px;
	.blog-post-wrap {
		.entry-link {
			display: block;
			border: 1px solid $card-border-grey;
			border-radius: 4px;
			box-shadow: 0 0 10px rgba(0,0,0,.13);
			height: 100%;
			background: $card-shadow-white;
		}
	}
	.card.password-protected {
		background-color: $bg-grey-related;
	}
}

// videos feed

.category-videos, .category-videoarchiv {
	.subtitle {
		margin-top: 50px;
		color: $text-lightgrey-2;
	}
	.blog-feed {
		.card {
			padding: 25px;
			.card-title {
				margin-bottom: 4px;
			}
			.reading-time {
				display: inline-block;
				margin-bottom: 2px;
			}
			.card-text {
				margin-top: 16px;
			}
		}
		.blog-post-img, .video-post-img-protected {
			position: relative;
			&:after {
				content: "";
				font-family: $faPro;
				font-size: 74px;
				font-weight: $faSolid;
				color: white;
				text-shadow: -4px 4px 12px rgba(0, 0, 0, 0.3);
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
			}
		}
		.video-post-img-protected {
			&:after {
				content: "";
			}
		}
		.blog-post-content {
			background: transparent;
			padding: 0 20px 0 35px;
			line-height: 1;
			.blog-post-authors {
				float: right;
				margin-right: -20px;
				img {
					display: inline-block;
					width: 48px;
					height: 48px;
					border-radius: 50%;
					border: 4px solid white;
					&:nth-child(2n), &:nth-child(3n), &:nth-child(4n), &:nth-child(5n) {
						margin-left: -10px;
					}
				}
			}
			.reading-time {
				position: static;
				padding: 0;
				background: transparent;
			}
			p {
				font-style: normal;
				margin-top: 9px;
			}
		}
	}
	.icon-download {
		text-align: center;
		color: #a08fbd;
		font-size: 27px;
		border-radius: 50%;
		display: block;
    	line-height: 1;
		padding: 22px;
		box-shadow: $card-shadow;
		position: absolute;
	    right: 25px;
	    bottom: -10px;
	    background: white;
	    cursor: pointer;
		&:hover {
			opacity: 1;
			color: #401D7B;
		}
	}
}


// icons

.reading-time {
	&:before {
		font-family: $faPro;
		content: "\f017";
		margin-right: .4em;
		font-size:.9em;
	}
}


@include media-breakpoint-down(lg) {
	#post-categories {
		position: relative;
	}
}

@include media-breakpoint-up(md) {
    .blog, .category, .tag {
		.featured-post-wrap {
			.featured-image {
			}
		}
		.search-form {
			position: absolute;
			right: 0;
			top: 50%;
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
		}
	}
}

@include media-breakpoint-up(xl) {
	.single-post, .blog, .category, .tag {
		#content {
			padding-top: calc(52px + 42px);
		}
	}
	.progress-container {
		top: calc(52px + 45px);
	}
	.blog-feed {
		margin-bottom: 150px;
	}
	.admin-bar {
		#post-categories, .progress-container {
			margin-top: 32px;
		}
	}
}

@include media-breakpoint-only(md) {
	.category-videos, .category-videoarchiv {
		.blog-feed {
			.blog-post-content {
				padding: 0;
				.blog-post-authors {
					margin-right: 0;
					img {
						width: 40px;
						height: 40px;
					}
				}
			}
			.col-md-5 {
				padding-right: 20px;
			}
		}
		.icon-download {
    		font-size: 20px;
   			padding: 18px;
   			right: -10px;

		}
	}
}

@include media-breakpoint-down(sm) {
	.blog-feed {
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.blog, .blog-feed {
		.blog-post-content {
			padding: 20px;
		}
	}
	#post-categories {
		text-align: center;
	}
	.post-tags {
		text-align: center;
		margin-bottom: 40px;
		h3 {
			font-size: 6vw;
			margin-bottom: 20px;
		}
	} 
	.post-categories a {
		margin-right: 2px;
	}
	.blog, .category, .tag {
		.entry-title {
			text-align: center;
		}
		.search-form {
			margin-top: 20px;
		}
		.featured-post-wrap {
			.featured-image {
				padding-top: 59%;
				border-top-right-radius: $card-radius;
				border-top-left-radius: $card-radius;
			}
			.featured-content {
				padding: 20px;
				.entry-title {
					font-size: 24px;
					margin-bottom: 10px;
					text-align: left;
				}
				p {
					font-size: 20px;
					line-height: 1.2;
					margin-bottom: 10px;
				}
			}
		}
	}
	.category-videos, .category-videoarchiv {
		.subtitle {
			margin-top: 40px;
			margin-bottom: 40px;
			text-align: center;
		}
		.blog-feed {
			.post-image {
				padding: 0;
			}
			.blog-post-content {
				padding: 0;
				.reading-time {
					margin-top: 4px;
				}
				.blog-post-authors {
					display: none;
				}
			}
		}
	}
}
