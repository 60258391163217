
#content {
	padding-top: 52px;
}

#header {
	position: fixed;
	width: 100%;
	z-index: 21;
	background: $purple;
	color: white;
	min-height: 52px;
	ul {
		text-align: right;
	}
	li {
		display: inline-block;
	}
	a {
		color: white;
	}
}
#site-title {
	line-height: 52px;
}
.logo-suffix {
    position: relative;
	left: -4px;
    top: 1px;
}

#mobile-menu, #login-button {
	font-size: 32px;
	//height: 32px;
	width: 52px;
	padding: 10px;
	position: absolute;
	z-index: 15;
}
#mobile-menu {
	left: 0;
}
#login-button {
	right: 0;
}


.mm-wrapper--position-left .mm-slideout, .mm-wrapper--position-right .mm-slideout {
    will-change: unset;
}
.mm-slideout {
    z-index: unset;
}

@include media-breakpoint-up(xl) {
	.header-wrap {
		position: relative;
	}
	#branding {
		width: 20%;
	}
	#menu-desktop {
		width: 80%;
		ul {
			margin: 0;
		}
	}
	#menu-main-desktop-menu {
		font-size: 16px;
		> .menu-item {
			padding: 0;
			margin: 0;
			&:after {
				content: ' • ';
				opacity: .4;
				font-family: 'Roboto', sans-serif;
				//margin-left: 8px;
				//margin-right: 8px;
			}
			&:last-of-type:after,
			&:nth-last-of-type(2):after {
				content: none;
			}
			&:hover {
				.sub-menu {
					display: block;
				}
			}
			&:hover > a, a.current-menu-item {
		    	border-bottom-color: rgba(255,255,255,.1);
		    	font-weight: $regular;
			}
			&.sub-menu-small-parent {
				position: relative;
				&.login {
					.sub-menu-small {
						right: 0;
					}
				}
				.sub-menu-small {
					right: 17px;
				}
			}
			&.login {
				opacity: .5;
				&:hover {
					opacity: 1;
				}
			}
			> a {
				text-align: center;
				display: inline-block;
			    padding-top: 14px;
			    padding-bottom: 7px;
			    border-bottom: 7px solid transparent;
			    margin-left: 8px;
				margin-right: 8px;
			    &::before {
					content: attr(title);
					height: 0;
					display: block;
					visibility: hidden;
					overflow: hidden;
					user-select: none;
					pointer-events: none;
					font-weight: $medium;
				}
			}
			a {
				letter-spacing: -.21px;
			}
			.sub-menu {
				display: none;
				position: absolute;
				background: $purple;
				text-align: left;
				padding: 40px 37px;
				&:before {
					content: "";
					display: block;
					background-color: $lightorange;
					position: absolute;
					z-index: -1;
					height: 100%;
					width: 100%;
					left: -7px;
					bottom: -7px;
				}
				&.sub-menu-small {
					width: 226px;
				}
				&.sub-menu-medium-small {
					width: 230px;
				}
				&.sub-menu-big {
					left: 0;
					h6 {
						color: #FFC3A3;
						padding-bottom: 10px;
						border-bottom: 1px solid white;
					}
					i {
						display: block;
						font-style: italic;
						opacity: .7;
					}
					.sub-menu-inner {
						text-align: left;
						margin-top: 40px;
					}
					.teaser {
						display: block;
						margin-top: 40px;
					}
				}
				li {
					display: block;
					padding-left: .5em;
					text-indent: -.7em;
					margin-bottom: 20px;
					&:last-of-type {
						margin-bottom: 0;
					}
					&:before {
						position: relative;
						top: 3px;
						content: "\f054";
						opacity: .43;
						margin-right: 7px;
						font-family: $faPro;
						font-size: 9px;
						line-height: 16px;
						font-weight: $faSolid;
						vertical-align: top;
					}
					a {	
					    display: inline-block;
					    width: calc(100% - 6px);
					    text-indent: 0;
						//display: block;
						&.current-menu-item, &:hover {
							font-weight: $medium;
						}
						&.admin-link {
							margin-left: .23em;
						}
					}
				}
				strong {
					display: block;
					margin-bottom: 10px;
				}
			}
		}
		.login, .wpml-ls {
			background: rgba(0,0,0,.1);
		}
		.wpml-ls {
			display: inline-block;
			padding: 12px 0;
			border: 0;
		}
		.login {
			//margin-right: -5px;
		}
	}
	.tag, .single-post, .category {
		#menu-main-desktop-menu .menu-item-blog > a {
	    	border-bottom-color: rgba(255,255,255,.1);
	    	font-weight: $regular;
	    }
	}
}
 
@include media-breakpoint-down(lg) {
	#branding {
		text-align: center;
	}
	#login-button, #mobile-menu {
		background: rgba(0,0,0,.2);
	}
	.site-title {
		text-align: center;
	}
	.profile-pic {
		display: block;
		img {
			display: block;
			border-radius: 50%;
			border: 3px solid white;
			width: 32px;
			height: 32px;
		}
	}
	.mm-slideout .header-wrap {
		.fa-close {
			display: none;
		}
	}
	/*#mm-3 {
		display: block !important;
		z-index: 1;
	    -webkit-transform: translate3d(0,0,0);
	    transform: translate3d(0,0,0);
	}*/
	.mm-btn.mm-btn_close.mm-navbar__btn {
		display: none;
	}
	.mm-menu_opened + .mm-slideout .header-wrap {
		.fa-bars {
			&:before {
				content: "\f00d";
				text-align: center;
				display: block;
			}
		}
	}
	.wpmm-menu {
		font-weight: $medium;
		.fa {
			font-weight: $faRegular;
			width: 32px;
		}
		.mm-navbars--top .mm-navbar:first-child {
			display: none;
		}
		.mm-navbars_top .mm-navbar {
			display: contents;
		}
		.mm-navbar__title {
			padding-top: 15px;
			padding-bottom: 15px;
		}
		//.mm-listitem, .mm-panel, .mm-navbar__title
		&.mm-menu {
			//--mm-color-background: #eee;
			--mm-color-text: white;
			--mm-color-background-emphasis: #4e1c71;
			--mm-color-icon: rgb(255 255 255 / 0.4);
			--mm-color-text: rgb(255 255 255 / 0.8);
			--mm-color-text-dimmed: rgb(255 255 255 / 0.4);
			--mm-color-background-highlight: rgb(255 255 255 / 0.05);
		}
		.mm-listitem:after, .mm-navbars_top {
			border-color: #81589e;
		}
		.mm-listitem:after {
			left: 0;
		}
		.mm-listitem_selected>.mm-listitem__text {
			background-color: #4e1d71;
			color: white;
			/*margin-top: -1px;
			&::after {
				border: none;
			}*/
		}
		.mm-listitem__btn:not(.mm-listitem__text) {
			border-left: none;
		}
		.mm-listitem_divider {
			//line-height: 25px;
			padding: 0;
			background-color: #81589e;
			margin-top: -1px;
			&::after {
				border: none;
			}
		}
		.mm-btn_close:after, .mm-btn_close:before {
			width: 12px;
			height: 12px;
			border-color: white;
		}
		.mm-btn_close:before {
			right: 8px;
		    border-left-width: 3px;
		    border-top-width: 3px;

		}
		.mm-btn_close:after {
		    border-right-width: 3px;
		    border-bottom-width: 3px;
		}
	}
	.mm-navbars_bottom {
		.mm-navbar {
			.wpml-ls-link {
				text-align: left;
				padding-left: 20px;
			}
		}
	}
	.mm-navbars_top .mm-navbar_tabs:not(:last-child) {
		display: none;
	}
	.mm-menu_navbar_top-2 .mm-panels {
		top: 50px;
	}
	/*.mm-navbars_top, .mm-tabstart, .mm-tabend {
		display: none;
	}
	.mm-menu_navbar_top-1 .mm-panels {
		top: 0;
	}*/
}

@include media-breakpoint-down(sm) {
	#mobile-menu, #login-button {
		width: 72px;
		padding-left: 20px;
	    padding-right: 20px;
	}
}