.single {
	&.single-post {
		.h1, h1 {
			color: $text-purple;
		}
	}	
	.header {
		padding-top: 60px;
		padding-bottom: 36px;
		.reading-time {
			font-size: 17px;
			line-height: 32px;
			color: $text-lightgrey-2;
		}
		.entry-title {
			margin-bottom: 8px;
		}
		.entry-meta {
			margin-bottom: 0;
			opacity: .7;
			color: $text-purple-2;
			a {
				color: inherit;
				font-weight: $regular;
			}
		}
		.featured-image-wrap {
			margin-bottom: -120px;
		}
		.featured-image {
			width: 475px;
			max-width: 100%;
		}
	}
	&.single-post {
		.display-banner {
			.header {
				padding-bottom: 0;
			}
			.entry-excerpt {
				margin-top: 20px;
				margin-bottom: 38px;
			}
			.entry-meta {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
	.entry-excerpt {
		color: $text-grey-palatino;
		line-height: 1.125;
		margin-top: 3px;
		margin-bottom: 15px;
	}
	// has image
	&.single-post .display-banner, &.single-training .has-post-thumbnail {
		.header {
			padding-top: 20px;
			background-blend-mode: saturation;
			.post-meta, .reading-time, .entry-meta {
				color: $white-60;
				line-height: 30px;
			}
			.entry-title, .entry-meta a, .entry-excerpt {
				color: white;
			}
			.post-meta, .reading-time {
				padding-top: 15px;
			}
		}
		.entry-excerpt {
			margin-top: 35px;
			margin-bottom: 35px;
		}
	} 
	.featured-video { // hiding Gutenborg automatically added paragraphs in featured video
		margin-bottom: 40px;
		p {
			display: none;
		}
	}
	.entry-content {
		margin-bottom: 60px;
		.embed-responsive {
			margin-bottom: 50px;
		}
		
		
		.wp-block-pullquote {
    		padding: 1em 0;
    	}
		
		BLOCKQUOTE.wp-block-quote, BLOCKQUOTE.wp-block-quote p,
		FIGURE.wp-block-pullquote blockquote, FIGURE.wp-block-pullquote blockquote p {
			font-family: $palatino;
			font-size: $base-font-size * 1.4;
			/* font-weight: bold; */
			font-style: italic;
			text-align: center;
			line-height: 34px;
			color: $orange;
			padding: 0;
		}
		BLOCKQUOTE.wp-block-quote p,
		FIGURE.wp-block-pullquote blockquote p {
			
			margin: 0;
			
			&:before {
				content: "“";
			}
			&:after {
				content: "”";
			}
		}
		BLOCKQUOTE.wp-block-quote,
		FIGURE.wp-block-pullquote blockquote {
			padding: 26px 0 22px 0;
			border-top: $hr-grey dotted 1px;
			border-bottom: $hr-grey dotted 1px;
			margin-top: .5em;
			margin-bottom: 1em;
		} 
		cite {
			font-size: $base-font-size * 0.9;
			color: $text-grey;
			font-family: $roboto;
			font-style: normal;
		}  
		
		iframe {
			max-width: 100%;
			width: 100%;
		}
	}
	.featured-video-plus {
		iframe {
		}
	}
	// has featured video
	sidebar {
		h6 {
			margin-top: 0;
			margin-bottom: 17px;
		}
	}
	.social-media-share-buttons {
		display: none;
	}
}


// co:dify Core Extra-Info

.cdf-core-info {
	background-color: $bg-grey;
	margin: 10px -20px 10px -20px;
	padding: 20px 20px 20px 20px;
	
}


// Twitter Card-Styling
/* Das mal später checken mit Lana */

FIGURE.wp-block-embed-twitter blockquote.twitter-tweet {
  display: inline-block;
  font-size: $base-font-size * 2;
  border-color: #eee #ddd #bbb;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  margin: 10px 5px;
  padding: 20px;
}

FIGURE.wp-block-embed-twitter blockquote.twitter-tweet p {
  color: white;
  font-size: 25.2px;
  font-weight: normal;
  line-height: 1.3;
}

FIGURE.wp-block-embed-twitter blockquote.twitter-tweet > a {
  display: none;
}


FIGURE.wp-block-embed-twitter .wp-block-embed__wrapper BLOCKQUOTE.twitter-tweet {
	color: rgba(255,255,255,.6);
	background: $twitter-blue;
	font-size: 16.2px;
	width: calc(100% + 20px);
	margin-left: -5px;
	margin-right: -5px;
	padding: 30px;
	text-align: center;
}


.wp-block-embed__wrapper BLOCKQUOTE.twitter-tweet {

font-family: palatino, italic;
line-height: 24px;
color: rgba( #fff, .9 );

}

.wp-block-embed__wrapper BLOCKQUOTE.twitter-tweet P { margin-bottom:0; }


.wp-block-embed__wrapper BLOCKQUOTE.twitter-tweet a:not([class]) {
  		@include link-underline($twitter-blue, #fff, $textselection, darken( $twitter-blue, 20% ), darken( $twitter-blue, 20% ));
		}

.r-vakc41 {
	max-width: 100%;
}
.twitter-tweet, .r-vakc41 {
	max-width: 100% !important;
}



// scroll progress bar
.progress-container {
  width: 100%;
  height: 2px;
  background: transparent;
}

.progress-bar {
  height: 2px;
  background: $orange;
  width: 0%;
  margin-top: -1px;
}

.content {
  padding: 100px 0;
  margin: 50px auto 0 auto;
  width: 80%;
}

// Moosend Form DIV has too much padding
#msf_1713489b-da28-4a9d-8f7e-56fef3f7f469 .content {
  padding: 0;
  margin: 0 auto 0 auto;
}


// authors
.entry-authors {
	text-align: center;
	.author-wrap {
		text-align: left;
		border-top: 1px dashed $hr-lightgrey;
		padding-top: 30px;
		padding-bottom: 50px;
		&:first-of-type {
			border-top: 1px solid $hr-grey;
		}
		&:last-of-type {
			border-bottom: 1px solid $hr-grey;
		}
		.codify-blog-author-image {
			display: inline-block;
			border-radius: 50%;
			margin-right: 18px;
		}
		img {
			vertical-align: top;
		}
		.codify-blog-author-content {
			display: inline-block;
			width: calc(100% - 75px - 23px);
		}
		span {
			font-family: $palatino;
			color: $text-lightgrey-2;
			font-style: italic;
		}
		.codify-blog-author-name {
			color: $text-purple-3;
		}
	}
	.author-contact {
		position: relative;
		margin-top: -28px;
	}
}

// comments
.entry-comments {
	color: $text-grey-palatino;
	padding-bottom: 150px;
	h3 {
		margin-top: 40px;
	}
	.comment-form-comment {
		label {
			display: none;
		}
	}
	.reply {
		text-align: right;
		.comment-reply-link {
			position: relative;
			padding-left: 34px;
			&:before {
				color: white;
				content: "\f3e5";
				font-family: $faPro;
				font-weight: $faSolid;
				font-size: 16px;
				position: absolute;
				padding: 0px;
				padding-left: 25px;
				opacity: 0.6;
				left: 0;
			}
		}
	}
	cite {
		font-style: normal;
		color: $text-purple;
		font-weight: $regular;
	}
	.avatar {
		border-radius: 50%;
	}
	.akismet_comment_form_privacy_notice {
		font-size: .67em;
	}
	.comment-author {
		margin-bottom: 12px;
		img {
			margin-right: 10px;
		}
		a {
			font-style: normal;
			font-weight: $regular;
		}
	}
	.comment-form-comment {
		textarea {
			width: 100%;
			border-color: $text-grey-palatino;
		}
	}
	.form-submit {
		text-align: right;
	}
}

.entry-sidebar {
	height: max-content;
	.sidebar-content {
		padding-bottom: 25px;
		&:not(:first-of-type) {
			border-top: 1px dashed $hr-lightgrey;
			padding-top: 54px;
		}
		ol {
		  //margin: 0 0 1.5em;
		  padding: 0;
		  counter-reset: item;
		}

		ol > li {
		  margin: 0;
		  //padding: 0 0 0 2em;
		  //text-indent: -2em;
		  list-style-type: none;
		  counter-increment: item;
		  padding: 10px 15px 10px 15px;
		  transition: all .3s;
		  margin-left: -10px;
		  margin-right: -10px;
		  &.current-post { //, &:hover {
		  	background: rgba(246, 119, 51, .2);
		  	color: $black-80;
		  	margin-left: -15px;
		  	padding-left: 15px;
		  	margin-right: -15px;
		  	padding-right: 15px;
		  }
		  a, span {
		  	display: inline-flex;
		  	width: calc(100% - 2.5em);
		  }
		  a {
		  	color: inherit;
		  	//display: block;
		  }
		}

		ol > li:before {
			color: $lightorange;
			display: inline-block;
			width: 2em;
			padding-right: 1em;
			//font-weight: bold;
			//text-align: right;
			content: counter(item) ".";
		}

	}
}

// blog series pagination 

SPAN#blogseriestitle {
	color: $orange;
	font-weight: $medium;
	font-size: 1em;
	display:block;
	text-transform:uppercase;
	margin: 0 0 -3px 0;
	letter-spacing: -0.015em;
	opacity:.6;
}


.blog-series {
		font-size:.85em;
		font-weight:$regular;
		color: $orange-dark;
}

.pagination-nav {
	> a {
		@extend .btn;
		background: $lightorange;
		text-transform: none;
		box-shadow: -2px 2px 4px 0 rgba(246, 119, 51, .2);
		border-radius: 7px;
		&:hover {
			background: $orange;
		}
		&[rel="prev"] {
			float: left;
		}
		&[rel="next"] {
			float: right;
		}
	}
	.nav-wrap {
		display: inline-block;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		span.nav {
			border-radius: 7px;
			margin-bottom: 20px;
		}
	}
}

// password protect

.single {
	.password-protected-form {
		.password-protect-p {
			+ p {
				padding-bottom: 0;
				margin-bottom: 0;
			}
		}
		label {
			font-weight: $medium;
			font-size: 20px;
			color: $text-lightgrey-2;
		}
	}
	.post:not(.has-post-video) {
		.password-protected-form {
			@extend .card;
			padding: 30px;
			.password-protected-title {
				margin-top: 0;
			}
		}
	}
	.post-password-required {
		.password-protected-video {
			position: relative;
			img {
				filter: grayscale(1);
				//opacity: 0.5 !important;
				width: 100%;
			}
			.password-protected-form {
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;
				background-color: rgba(255,255,255,.7); // opacität video bild
				h5, p, label {
					display: none;
				}
				.pw-input-wrap {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}
			}
		}
		.password-protected-video-content {
			background-color: $text-lightgrey-2;
			position: relative;
			display: flex;
			z-index: 1;
			padding: 18px 22px;
			margin-bottom: 45px;
			p {
				font-size: 16px;
				color: white;
				margin-bottom: 0;
				padding-bottom: 0;
			}
			.btn {
				margin-left: auto;
			}
			.btn-black {
				height: max-content;
				transform: translate(0, 25%);
			}
		}
		.fading-div {
			position: relative;
			&:after {
				content: "";
				display: block;
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;
				background-image: linear-gradient(transparent, white);
				background-image: -webkit-linear-gradient(top, rgba(255,255,255,0.001) 0%, #fff 100%);
			}
		}
	}
}

// pie chart

@include media-breakpoint-up(lg) {
	.single {
		&.single-post .display-banner, &.single-training .has-post-thumbnail {
			.header {
				padding-top: 45px;
			}
		}
		&.single-post .display-banner { //, &.single-training .has-post-thumbnail {
			.header {
				padding-bottom: 100px;
				.post-meta, .reading-time {
					padding-top: 70px;
				}
			}
		}
		&.single-post { //, &.single-training { 
			.header {
				.entry-title {
					font-size: 48px;
					line-height: 48px;
					letter-spacing: -1px;
					margin-top: 2px;
				}
			}
		}
		.header {
			.entry-excerpt {
				font-size: 32px;
				letter-spacing: -.42px;
			}
		}
	}
	.entry-sidebar {
		padding-left: 75px;
	}
	.float-right { // the advertising block for blog posts
			position: absolute;

	    flex: 0 0 calc(0.25 * 1140px);
	    max-width: calc(0.25 * 1140px);
	    right: calc(-0.333333333333 * 1140px);

	    padding: 20px;
	    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
			border: none;
			border-radius: 4px;
			text-align: center;


			IMG { margin:0; }
			H1, H2, H3, H4, H5, H6 { font-size: 1.2em; color: #fff; margin-top: 0; }
			P { font-size: 0.9em; color: #fff; line-height: 1.35em; padding: 0 4px 0 4px; }
			A.ad-link { width: 100%; }

			.ad-button { margin: 10px -20px -20px -20px; }

			.starburst {
				margin: -36px 0 0 -46px;
				position: absolute;
  			// z-index: 9999; 

 			 	width: 92px;
 			 	// transform: rotate(20deg);
 			 	color:#fff;
 			 	background-color:#000;
 			 	text-align: center;
 			 	vertical-align: middle;

				transform: rotate(-22deg);

				text-transform: uppercase;
				font-size: 0.6em;
				line-height: 1.2em;
				font-weight: bold;


  			padding: 20px;
  			box-shadow: 4px 4px;

  			aspect-ratio: 1;
  			clip-path: polygon(100.00% 50.00%,95.82% 54.01%,99.24% 58.68%,94.43% 61.91%,96.98% 67.10%,91.69% 69.44%,93.30% 75.00%,87.68% 76.38%,88.30% 82.14%,82.53% 82.53%,82.14% 88.30%,76.38% 87.68%,75.00% 93.30%,69.44% 91.69%,67.10% 96.98%,61.91% 94.43%,58.68% 99.24%,54.01% 95.82%,50.00% 100.00%,45.99% 95.82%,41.32% 99.24%,38.09% 94.43%,32.90% 96.98%,30.56% 91.69%,25.00% 93.30%,23.62% 87.68%,17.86% 88.30%,17.47% 82.53%,11.70% 82.14%,12.32% 76.38%,6.70% 75.00%,8.31% 69.44%,3.02% 67.10%,5.57% 61.91%,0.76% 58.68%,4.18% 54.01%,0.00% 50.00%,4.18% 45.99%,0.76% 41.32%,5.57% 38.09%,3.02% 32.90%,8.31% 30.56%,6.70% 25.00%,12.32% 23.62%,11.70% 17.86%,17.47% 17.47%,17.86% 11.70%,23.62% 12.32%,25.00% 6.70%,30.56% 8.31%,32.90% 3.02%,38.09% 5.57%,41.32% 0.76%,45.99% 4.18%,50.00% 0.00%,54.01% 4.18%,58.68% 0.76%,61.91% 5.57%,67.10% 3.02%,69.44% 8.31%,75.00% 6.70%,76.38% 12.32%,82.14% 11.70%,82.53% 17.47%,88.30% 17.86%,87.68% 23.62%,93.30% 25.00%,91.69% 30.56%,96.98% 32.90%,94.43% 38.09%,99.24% 41.32%,95.82% 45.99%);

			}

	}
}

.float-right-dark {
			color: #fff;
			H1, H2, H3, H4, H5, H6 { color: #fff;  }
			P { color: #fff; }
 }

.float-right-bright {
			color: #000;
			H1, H2, H3, H4, H5, H6 { color: #000;  }
			P { color: #000; }
}

@include media-breakpoint-down(lg) {
	.float-right {
		display: none;
	}
}

@include media-breakpoint-down(sm) {
	.pagination-nav {
		.nav-wrap {
			width: 100%;
			transform: translate(-50%, 70px);
		}
	}
}
@include media-breakpoint-only(lg) {
	.pagination-nav {
		.nav-wrap {
			width: 100%;
			transform: translate(-50%, 70px);
		}
	}
}

@include media-breakpoint-down(sm) {
	.teaser-video-wrap {
		margin-left: auto;
		margin-right: auto;
	}
	.single {
		.header {
			padding-top: 36px;
			.entry-title, .post-meta, .entry-excerpt, .entry-meta {
				text-align: center;
			}
			.post-meta {
				margin-bottom: 8px;
			}
		}
		.entry-excerpt {
			font-size: 24px;
		}
		&.single-post .display-banner .entry-excerpt {
			margin-top: 55px;
		}
	}
	.entry-authors {
	    margin-top: 40px;
	    margin-bottom: 40px;
	}
	.single .post-password-required .password-protected-video .password-protected-form .pw-input-wrap {
		width: 90%;
	}
}

@media (min-width: 1360px) {
	.single {
		.social-media-share-buttons {
			display: grid;
		    row-gap: 10px;
		    font-size: 32px;
		    position: absolute;
		    margin-left: -90px;
		    a {
		    	color: #999999;
		    	transition: color .3s;
		    	&:hover {
		    		color: $lightorange;
		    	}
		    }
		}
	}
}