$theme-colors: (
     "primary" : #FF69B4,
     "purple" : #57207E
);
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1230px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);

$grid-columns:                12;
$grid-gutter-width:           20px;

$enable-grid-classes:       true;

@import './node_modules/bootstrap/scss/bootstrap';
@import './components/variables.scss';
@import './components/bootstrap.scss';
@import './components/typography.scss';
@import './components/table.scss';
@import './components/general.scss';
@import './components/layout.scss';
@import './components/header.scss';
@import './components/footer.scss';
@import './components/blog.scss';
@import './components/archive.scss';
@import './components/page.scss';
@import './components/post.scss';
@import './components/about.scss';
@import './components/author.scss';
@import './components/encyclopedia.scss';
@import './components/consulting.scss';
@import './components/home.scss';
@import './components/resource.scss';
@import './components/search.scss';
@import './components/training.scss';
@import './components/gutenberg.scss';
@import './components/forms.scss';
@import './components/wpuf_forms.scss';