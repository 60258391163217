
.alignwide {
	//position: relative;
    //width: fit-content;
}

/*
.wp-block-table table {
	@extend .table;
	td, th {
		@extend .table td;
	}
}
*/


@include media-breakpoint-up(lg) {
	.alignwide:not(.wp-block-pullquote) {
		margin-right: -20%;
	}
	.alignfull {
		position: relative;
		width: $container-lg-inner;
	}
}

@include media-breakpoint-up(xl) {
	.alignfull {
		position: relative;
		width: $container-xl-inner;
	}
}

// content classes

.infobox, .entry-content .infobox {
	border-radius: $card-radius;
	background: $bg-grey;
	padding: 30px;
	margin-top: 40px;
	margin-bottom: 40px;
	h1, h2, h3, h4, h5, h6 {
		margin-top: 0;
	}
	li, p, span:not([class*="zp-InText-Citation"]) { 
		font-size: 85%;
		line-height: 1.5;
	}
	p:last-of-type, ul li:last-of-type, .wp-block-columns {
		margin-bottom: 0;
	}
	A:not([class]) {
	@include link-underline($bg-grey, $text-darkgrey, $textselection, $purple, $purple);
	}
}


.infobox P {
		padding-bottom: 0;
   	 	margin-bottom: 1rem;
}

.infobox P:last-of-type {
		padding-bottom: 15px;
	}

.infobox H2, .infobox H3, .infobox H4, .infobox H5, .infobox H6 {
		padding-bottom: 0;
   	 	margin-bottom: 1rem;
   	 	font-size: 90%;
}


// resources

.wp-block-media-text.card-direction-row {
	position: relative;
	min-height: 200px;
	a {
		/*display: block;
		&:after {
			content: "";
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
		}
		&:hover {
			background: none;
		}*/
	}
	img.size-thumbnail {
		width: 200px;
	}
	h2 {
		font-size: 22px;
	}
	&.orange-bg, &.purple-bg {
		a {
			border-top-left-radius: $card-radius;
			border-bottom-left-radius: $card-radius;
		}
	}
	&.orange-bg {
		a {
			background: #f9a87c;
		}
	}
	&.purple-bg {
		a {
			background: #a58fcb;
		}
	}
	.wp-block-media-text__content {
		padding-left: 25px;
		padding-right: 25px;
	}
}

.wp-block-group.card {
	@extend .card;
	//background: $bg-section-grey;
	padding: 0 20px;
	margin-top: 20px;
	margin-bottom: 20px;
	//margin-left: -20px;
    //margin-right: -20px;
	h3 {
		//margin-top: 0;
	}
	ul {
		li {
		}
	}
}
.wp-block-group.values {
	.wp-block-group__inner-container > p {
		color: $text-purple-2;
	}
}

.wp-block-columns {
	.wp-block-column {
		.wp-block-group.card {
			margin-right: 0;
			margin-left: 0;
		}
		&:first-of-type {
			.wp-block-group.card {
				margin-left: -40px;
			}
		}
		&:last-of-type {
			.wp-block-group.card {
				margin-right: -40px;
			}
		}
	}
}

.values H5, .values H3 {
		margin-bottom: 30px;
	}
	
.mission {
	H6.plan SPAN {
		font-face: $faPro;
		font-weight: $faRegular;
	}
	P.plan {
		color: $lightpurple;
		font-weight: $medium;
		line-height: 1.8em;
	}
	P.plan SPAN {
		opacity: .6;
	}
	> .wp-block-group__inner-container > h6 {
		margin-top: 90px;
		margin-bottom: 11px;
		&:first-of-type {
			margin-top: 5px;
		}
	}
}

.aboutimg { 
	margin-bottom: 40px; 
	margin-top: 40px;
	}

//.values,
.accordion .accordion-body {
	ul {
		padding-right: 150px;
	}
}

//.values,
//.accordion .accordion-body {
body {
	.entry-content, .accordion-body {
		ul:not([class*="wpuf"]) {
			list-style-type: none;
			padding-left: 0;
			li {
				font-weight: $light;
				margin-bottom: .8em; // 1.33em;
				padding-left: 1em;
				&:before {
					display: inline-block;
					content: "";
					opacity: .5;
					height: 0; 
					border-top: .4em solid transparent;
					border-bottom: .4em solid transparent;

					border-left: .4em solid $lightorange;
					margin-left: -1em;
					margin-right: 12px;
				}
			} 
		}
		.wp-block-table, .tablestyle {
			ul:not([class*="wpuf"]) {
				list-style-type: disc;
				padding-left: 1em;
				li {
					padding-left: 0;
					&:before {
						content: none;
					}
				}
			}
		}
		.wpuf-dashboard-container {
			ul {
				padding-left: 0;
				li {
					&:before {
						content: none;
					}
				}
			}
		}

		.wp-block-wpuf-content-restriction { /* Hide restricted content message */

		display:none;

		}
	}
}

@include media-breakpoint-up(lg) {
	.wp-block-group.card {
		padding: 0 40px 40px 40px;
		margin: 28px -40px 40px;
	}
}

@include media-breakpoint-down(lg) {
	.accordion {
		.accordion-body {
			padding: 40px 54px;
			ul {
				padding-right: 0;
			}
		}
	}
}

@media (max-width: 599px) {
	.no-break .wp-block-column {
	    flex-basis: 50% !important;
	}
}

@media (min-width: 782px) {
	.wp-block-column:not(:first-child) {
	    margin-left: 50px;
	}
}

