
// search form
.search-form {
	width: 340px;
	float: right;
	max-width: 100%;
	.screen-reader-text {
		color: white;
	}
	.search-field, .search-submit {
		line-height: 30px;
		padding: 6px 10px;
	}
	.search-field {
		color: white;
		background: rgba(0,0,0,.35);
		border: none;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		margin-right: -4px;
		padding-left: 10px;
		width: calc(100% - 51px);
	}
	input[type=search] {
		margin-right: -5px;
	}
	input::placeholder {
		color: white;
	}
	.search-submit {
		background-color: $bg-purple;
		color: white;
		border: none;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
		width: 50px;
		&.icon-white i {
			color: white;
		}
		&:hover {
			background-color: lighten($bg-purple, 10%);
		}
	}
}

.hero-search {
	.search-form {
		width: 100%;
		label {
			width: calc(100% - 134px);
			input[type=search] {
				width: 100%;
				margin-right: -8px;
			}
		}
		.search-field, .search-submit {
			display: inline-block;
			font-size: 22px;
			line-height: 1;
			padding: 15px 19px;
		}
		.search-field {
			background-color: lighten( $purple, 60% );//#EBDFFF;
			color: $text-grey-2;
			border-top-left-radius: 8px;
			border-bottom-left-radius: 8px;
			width: calc(100% - 151px);
			border: none;
		}
		input::placeholder {
			color: lighten( $purple, 40% );;
			//display: none;
		}
		.search-submit {
			color: white;
			background-color: $orange;
			border-top-right-radius: 8px;
			border-bottom-right-radius: 8px;
			border: 1px solid $orange;
			//padding-bottom: 21px;
			width: 150px;
			margin-left: -1px;
			&:hover {
				background-color: $lightorange;
			}
			i {
				margin-right: 10px;
			}
		}
	}
}

// encyclopedia search
.single-encyclopedia {
	.head {
		#search-wrap, #glossary-search {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			width: 100%;
		}
		#glossary-search {
			background: white;
		}
	}
	.hentry {
		position: relative;
	}
	.hero-search .search-form {
		.search-field {
		    color: $text-darkgrey;
		    background: rgba(0, 0, 0, 0.06);
		}
		.search-submit {
			background: $lightorange;
			border-color: $lightorange;
			padding-top: 15px;
			padding-bottom: 15px;
			&:hover {
				background: $orange;
			}
		}
		input::placeholder {
			color: $text-darkgrey;
			opacity: .5;
		}
		input#search {
			//width: calc(100% - 116px);
			//width: calc(100% - 116px - 16.6667%)
		}
	}
}

.icon-search:before {
	color: #D2D2D2;
	font-size: 46px;
	display: block;
	float: right;
	margin-top: -10px;
	border-radius: 50%;
	box-shadow: $card-shadow;
    height: 130px;
    width: 130px;
    line-height: 130px;
    text-align: center;
}

.icon-search:hover:before {
	color: $orange;
}

#glossary-search .search-field {
	//background-color: white;
	background-color: lighten( $purple, 60% );
}


.error404 {
	.search-form {
		float: none;
		margin: auto;
		.search-field {
			color: $text-darkgrey;
			background-color: rgba(0,0,0,.06);
		}
		.search-submit {
			color: white;
			background-color: $lightorange;
			&:hover {
				background-color: darken($lightorange, 10%);
			}
		}
	}
} 

@include media-breakpoint-up(md) {
	.single-encyclopedia .search-area {
		position: absolute;
		top: 50px;
	}
}

@include media-breakpoint-down(md) {
	.single-encyclopedia .search-area {
		text-align: center;
		margin-top: 40px;
		.icon-search:before {
			float: none;
			display: inline-block;
		}
	}
}

@include media-breakpoint-down(sm) {
	.search-form {
		width: 100%;
	}
	#glossary-search {
		.search-field, .search-submit {
			font-size: 18px;
			padding: 10px;
			line-height: 1.4;
			margin-left: -1px;
		}
		.search-field {
			width: calc(100% - 121px);
		}
		.search-submit {
			width: 120px;
		}
	}
}

// search page

.search-results {
	.entry-title {
		margin-top: 84px;
	}
}